import {
  Typography,
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  Link
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";

import React, { useState } from "react";
import Title from "../../components/Core/Title";
import FullWidthBannerImg from "../../components/Images/FullWidthBannerImg";
import { useLoaderData } from "react-router-dom";
import { Circle, NoEncryption } from "@mui/icons-material";

export default function Scenario() {
  const loaderData = useLoaderData();
  // console.log("scenario loaderdata", loaderData);

  return (
    <>
      <Box
        sx={{ width: { xs: "85%", md: "80%", xl: "65%" }, margin: "2% auto" }}
      >
        <Title
          text={loaderData.loader1.title !== null && loaderData.loader1.title}
        />
        <Grid
          container
          direction={"row"}
          justifyContent="center"
          alignItems="stretch"
          spacing={4}
          px={0}
        >
          {loaderData.loader1.scenario_content_block !== null &&
            loaderData.loader1.scenario_content_block.length > 0 &&
            <Grid item xs={12}>
              <Typography sx={{ mt: 1, mb: 1 }} variant="h4" component="div">
                Index
              </Typography>
              <List dense>
                {loaderData.loader1.scenario_content_block.
                filter(cb => cb.title !== "" && cb.title !== null).map((cb) =>
                  <Link href={`#${cb.id}_${cb.title}`} >
                    <ListItem>
                      <ListItemIcon>
                        <Circle />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography variant="h5">
                          {cb.title}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </Link>
                )}
              </List>
            </Grid>

          }
          {loaderData.loader1.scenario_content_block !== null &&
            loaderData.loader1.scenario_content_block.length > 0 &&
            loaderData.loader1.scenario_content_block.map((cb) => (
              <>
                {cb.image !== "" && cb.image !== null && (
                  <Grid item xs={12} md={cb.width} my={{ xs: 1, md: 3}}>
                    <Box
                      component="img"
                      id={`${cb.id}_${cb.title}`}
                      sx={{
                        height: "auto",
                        width: "100%",
                        border: "none",
                      }}
                      alt={cb.image.meta.title}
                      src={cb.image.meta.download_url}
                    />
                  </Grid>
                )}
                {cb.body !== "" && cb.body !== null && (
                  <Grid item xs={12} md={cb.width}>
                    <Box>
                      <Divider sx={{ borderBottomWidth: 2 }} />
                    </Box>
                    <Box width="100%" mx={0} mt={4}>
                      <Typography
                        id={`${cb.id}_${cb.title}`}
                        variant="h5"
                        textAlign={{ xs: "center", md: "left" }}
                      >
                        {cb.title !== undefined &&
                          cb.title !== null &&
                          cb.title}
                      </Typography>
                      <Typography
                        pt={3}
                        variant="body1"
                        listStyleType={{xs: "none", md: "disc"}}
                        textAlign={{ xs: "left", md: "left" }}
                        dangerouslySetInnerHTML={{
                          __html:
                            cb.body !== undefined &&
                            cb.body !== null &&
                            cb.body,
                        }}
                      ></Typography>
                    </Box>
                  </Grid>
                )}
              </>
            ))}
        </Grid>
      </Box>
    </>
  );
}
