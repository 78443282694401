import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import axios from "axios";
import { Alert } from '@mui/material';
import { useLoaderData, useNavigate, useOutletContext } from "react-router-dom";
import CSRFToken from "../../loaders/CSRFToken";

export default function Login() {
    const [alert, setAlert] = useState(undefined);
    const [context] = useOutletContext();
    const [freezeForm, setFreezeForm] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const loaderData = useLoaderData();
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        axios.post(
            "/dj-rest-auth/login/",
            data,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': CSRFToken
                }
            }
        )
        .then(res => {
            window.location.href = "/authentication/my_account";
        })
        .catch(error => {
            // console.log(error);
            error.response.data.type = "error"
            setAlert(error.response.data);
            setFreezeForm(false);
        })
        .finally(() => setTimeout(() => {
            setFreezeForm(false);
            setAlert(undefined);
        }, 8000))
    };

    return (
        <>
            <Typography component="h1" variant="h5">
                Sign in
            </Typography>
            {alert !== undefined && Object.keys(alert).map(key => key !== "type" && <Alert severity={alert.type}>{alert[key]}</Alert>)}
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                    error={alert !== undefined && alert.type === "error"}
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    name="username"
                    autoComplete="username"
                    autoFocus
                    disabled={freezeForm}
                />
                <TextField
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    error={alert !== undefined && alert.type === "error"}
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    disabled={freezeForm}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={freezeForm}
                    sx={{ mt: 3, mb: 2 }}
                >
                    Sign In
                </Button>
                <Grid container>
                    <Grid item xs>
                        <Link href={window.location.pathname.indexOf("login") === -1  ? "/authentication/password_reset" : "password_reset"} variant="body2">
                            Forgot password?
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link href={window.location.pathname.indexOf("login") === -1  ? "/authentication/registration" : "registration"} variant="body2">
                            Don't have an account? Sign Up
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}