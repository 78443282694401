import React, { useState } from 'react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import stringSimilarity from "string-similarity";
import { Typography } from '@mui/material';

// function SearchBar({ onSearchSelected }) {
function SearchBar(props) {
  const [items, setItems] = useState(props.items);
  const [reference, setReference] = useState('');
  // console.log("itemssss", items);

  function findAndExtractText(searchString, mainString) {
    if (typeof mainString !== 'string') {
      return { found: false };
    }
  
    const index = mainString.toLowerCase().indexOf(searchString.toLowerCase());
  
    if (index !== -1) {
      const start = Math.max(0, index - 30); // Start 30 characters before the match
      const end = Math.min(mainString.length, index + searchString.length + 30); // End 30 characters after the match
  
      // Extract the context around the match
      const contextBefore = mainString.substring(start, index);
      const contextAfter = mainString.substring(index + searchString.length, end);
  
      return {
        found: true,
        match: `...${contextBefore+searchString+contextAfter}...`
      };
    }
  
    return { found: false };
  }

  const handleOnSearch = (string, results) => {
    // Triggered when the user types in the search input
    // console.log("HANDLE ON SEARCH", string, results);
    setReference(string);
  };

  const handleOnHover = (item) => {
    // Triggered when the user hovers over an item in the suggestions list
    // console.log('Item hovered:', item);
  };

  const handleOnSelect = (item) => {
    // Triggered when the user selects an item from the suggestions list
    // console.log('Item selected:', item);
    props.onSearchSelected(item);
  };

  const formatResult = (item) => {
    // const matches = stringSimilarity.findBestMatch(item, iconsNames);
    // const bestMatch = matches.bestMatch.target;
    // console.log("findAndExtractText(item.body)", findAndExtractText(reference, item.body));
    return (
      <>
        <Typography variant="body1" style={{ display: 'block', textAlign: 'left', color: "black", fontSize: "16px", lineHeight: "20px"}}><strong>{item.title}</strong></Typography>
        {findAndExtractText(reference, item.short_description).found === true && <Typography variant="body1" style={{ display: 'block', textAlign: 'left', color: "black", fontSize: "16px", lineHeight: "20px"}} dangerouslySetInnerHTML={{__html:findAndExtractText(reference, item.short_description).match}}></Typography>}
        {findAndExtractText(reference, item.contentBlockTitle).found === true && <Typography variant="body1" style={{ display: 'block', textAlign: 'left', color: "black", fontSize: "16px", lineHeight: "20px"}}>{findAndExtractText(reference, item.contentBlockTitle).match}</Typography>}
        {findAndExtractText(reference, item.body).found === true && <Typography variant="body1" style={{ display: 'block', textAlign: 'left', color: "black", fontSize: "16px", lineHeight: "20px"}}>{findAndExtractText(reference, item.body).match}</Typography>}
      </>
    )
  }

  return (
    <div className="search-bar-container">
      <ReactSearchAutocomplete
        items={items}
        fuseOptions={{
              shouldSort: true,
              threshold: 0.6,
              location: 0,
              distance: 100,
              maxPatternLength: 32,
              minMatchCharLength: 1,
              keys: Object.keys(items[0])
        }}
        formatResult={formatResult}
        resultStringKeyName="title"
        onSearch={handleOnSearch}
        onHover={handleOnHover}
        onSelect={handleOnSelect}
        styling={{
          height: "44px",
          borderRadius: "0",
          border: "1px solid #dfe1e5",
          backgroundColor: "white",
          boxShadow: "rgba(32, 33, 36, 0.28) 0px 1px 6px 0px",
          hoverBackgroundColor: "#eee",
          color: "#212121",
          fontSize: "16px",
          padding: "0",
          fontFamily: "Arial",
          iconColor: "grey",
          lineColor: "rgb(232, 234, 237)",
          placeholderColor: "grey",
          clearIconMargin: '3px 14px 0 0',
          searchIconMargin: '0 0 0 16px',
          zIndex: "99"
        }}
      />
    </div>
  );
}

export default SearchBar;