import React, { useRef, useState } from 'react';
import jsPDF from 'jspdf';
import BylawsPDF from "./Templates/BylawsTemplate";

function App() {
	const [userPermissions, setUserPermissions] = useState(undefined);
	const [clientData, setClientData] = useState(undefined);

	// Retrieve client data by receiving the id from the url parameters
	// Then generate the pdf by using the template id in the url parameters
	
	const reportTemplateRef = useRef(null);

	const handleGeneratePdf = () => {
		const doc = new jsPDF({
			format: 'a4',
			unit: 'px',
		});

		// Adding the fonts.
		doc.setFont('Inter-Regular', 'normal');

		doc.html(reportTemplateRef.current, {
			async callback(doc) {
				await doc.save('document');
			},
		});
	};

	return (
		<div>
			<button className="button" onClick={handleGeneratePdf}>
				Generate PDF
			</button>
			<div ref={reportTemplateRef}>
				<BylawsPDF
                    corp_name="Corp Name"
                    city="Example City"
                    address="123 Example Street"
                    zip="12345"
                    state="Example State"
                    dir1="Bill Clinton"
                    dir2="Jeff Bezos"
                    qes_start="QES Start Examplet"
                />
			</div>
		</div>
	);
}

export default App;