import React from "react";

const BylawsTemplate = (props) => {
	const styles = {
		page: {
			marginLeft: '5rem',
			marginRight: '5rem',
			'page-break-after': 'always',
		},

		columnLayout: {
			display: 'flex',
			justifyContent: 'space-between',
			margin: '3rem 0 5rem 0',
			gap: '2rem',
		},

		column: {
			display: 'flex',
			flexDirection: 'column',
		},

		spacer2: {
			height: '2rem',
		},

		fullWidth: {
			width: '100%',
		},

		marginb0: {
			marginBottom: 0,
		},
	};
	return (
		<>
		<div style={styles.page}>
		<h1>BYLAWS</h1>
		<h1>OF</h1>
		<h1>{props.corp_name !== undefined && props.corp_name}</h1>
		<h1>A(N) {props.state !== undefined && props.state} CORPORATION</h1>
		<h2>ARTICLE I. SHAREHOLDERS</h2>
		<br/>
		<p>
			{props.dir1 !== undefined && props.dir2 == undefined && `${props.dir1} as sole Director of`}
			{props.dir1 !== undefined && props.dir2 !== undefined && `${props.dir1} and ${props.dir2} as directors of `}
			{props.corp_name !== undefined && props.corp_name}, a {props.state !== undefined && props.state} corporation, hereby adopt the following resolution pursuant to the Bylaws of the corporation and {props.state !== undefined && props.state} Statutes:</p>
		<p><h3>1.1 Annual Shareholder Meeting.</h3>An annual shareholders' meeting shall be held in each year on {props.qes_start!== undefined && props.qes_start} at {props.address !== undefined && props.address},  {props.city !== undefined && props.city}, {props.state !== undefined && props.state} {props.zip !== undefined && props.zip}, unless that day should fall on a legal holiday, in which event the meeting shall be held at the same hour on the next succeeding business day that is not a legal holiday. Annual  meetings shall be held at the principal executive office of the corporation or  at any other place within {props.state !== undefined && props.state} as may be determined  by the Board of Directors and designated in the notice of the meeting.</p>
		<br/><br/>
		<p><h3>1.2. Special Shareholder Meetings.</h3> Special shareholders’ meetings may be called for any purpose. Such meetings may be called at any time by the board or a majority of shareholders. On the written request of any person or persons entitled to call a special meeting, the Secretary shall inform the Board of Directors as to the call, and the board shall fix a time and place for the meeting. If the board fails to fix the time and place, the meeting shall be held at the principal executive office of the corporation at a time fixed by the Secretary.</p>
		<br/><br/>
		<p><h3>1.3. Action by Written Consent.</h3>Any action required by law to be taken at a shareholders’ meeting, except for the election of directors, and any other action that may be taken at a shareholders’ meeting may be taken without a meeting if a consent in writing, setting forth the action so taken, is signed by the holders of outstanding shares having not less than the minimum number of votes that would be necessary to authorize or take such action at a meeting at which all shares entitled to vote on that action were present and voted, if the consents of all shareholders entitled to vote were solicited in writing. Directors may not be elected by written consent except by unanimous written consent of all shares entitled to vote for the election of directors.</p>
		<br/><br/>
		<p><h3>1.4. Notice of Meetings.</h3> Written notice stating the place, day, and hour of the meeting and, in the case of a special meeting, the general nature of the business to be transacted, must be given to each shareholder of record entitled to vote at the meeting, not less than ten (10) nor more than sixty (60) days before the date of the meeting either personally or by mail or other means of written communication, addressed to the shareholder at the address of the shareholder appearing on the books of the corporation or given by the shareholder to the corporation for the purpose of notice. If no address appears or is given, notice must be addressed to the place where the principal executive office of the corporation is located or notice may be given by publication at least once in a newspaper of general circulation in the county in which the principal executive office is located. The notice shall be deemed to have been given at the time when delivered personally or deposited in the mail or sent by other means of written communication. An affidavit of mailing of any notice in accordance with the provisions of this section executed by the Secretary shall be prima facie evidence of the giving of the notice.</p>
		<br/><br/>
		<p><h3>1.5. Quorum.</h3>The presence, at any shareholders’ meeting, in person or by proxy, of persons entitled to vote of a majority of the shares of the corporation then outstanding shall constitute a quorum for the transaction of business. In determining whether this quorum requirement for a meeting have been met, any share that has been enjoined from voting or for any reason cannot be lawfully voted shall not be counted.</p>
		<br/><br/>
		<p><h3>1.6. Proxies.</h3>Every person entitled to vote at a shareholders’ meeting of the corporation, or entitled to execute a written consent authorizing action in lieu of a meeting, may do so either in person or by proxy executed in writing by the shareholder or authorized attorney in fact. No proxy shall be valid after eleven (11) months from the date of its execution unless otherwise provided in the proxy.</p>
		<br/><br/>
			<p><h3>1.7. Voting.</h3>Each outstanding share, regardless of class, shall be entitled to one vote on each matter submitted to a vote at a meeting of shareholders. The affirmative vote of the majority of shares represented at a meeting at which a quorum is present shall be the act of the shareholders unless the vote of a greater number or a vote by classes is required by the articles of incorporation, these bylaws, or the laws of {props.state !== undefined && props.state}.
			</p>
		<p></p><br/>
		<p><h3>1.8. Auction of Shares in Lieu of Dissolution.</h3>(a) Any shareholder shall have the right at any time to submit to arbitration the question of whether grounds for involuntary dissolution exist, as the grounds are specified below. On a finding that grounds exist, the arbitrator, who shall be appointed as specified below, shall order that the designated shares of the shareholder or shareholders shall be sold to the highest bidder among the shareholders as the arbitrator shall designate.</p><p>(b) As used in this section, the term “grounds for dissolution” shall mean any one or more of the following:</p>
		<p>(1) The directors are deadlocked in the management of the corporate affairs and the shareholders are unable to break the deadlock;</p>
		<p>(2) The shareholders are deadlocked in voting power, and have failed for at least two (2) consecutive annual meetings to elect successors to directors whose terms have expired or would have expired on the election of their successors;</p>
		<p>(3) The acts of the directors or those in control of the corporation are illegal, oppressive, or fraudulent; or</p>
		<p>(4) The corporate assets are being misapplied or wasted.</p>
		<p>(c) Arbitration shall be held in {props.city !== undefined && props.city}, {props.state !== undefined && props.state}, and shall be conducted under the rules of the American Arbitration Association. Judgment on the award of the arbitrator may be entered in any court having jurisdiction.</p>
			<p><h3>1.9. Consent of Stockholders in Lieu of Meeting.</h3> Any action to be taken at any annual or special meeting of Stockholders, may be taken without a meeting, without prior notice and without a vote, if a consent or consents in writing, setting forth the action to be taken, is signed by the holders of outstanding stock having not less than the minimum number of votes that would be necessary to authorize or take the action at a meeting at which all stock entitled to vote on the matter were present and voted is delivered to the Corporation. Every written consent will bear the date of signature of each Stockholder who signs the consent. However, no written consent will be effective unless the consent is delivered, either by hand or by certified or registered mail, within 90 days of the earliest dated consent, to the Corporation for inclusion in the minutes or filing with the corporate records.</p>
		<br/><br/>
		<h2>ARTICLE II.  BOARD OF DIRECTORS</h2>
		<p><h3>2.1. General Powers. </h3>Subject to the limitations of the articles of incorporation, these bylaws, and State Law  concerning corporate action that must be authorized or approved by the shareholders of the corporation, all corporate powers shall be exercised by or under the authority of the Board of Directors, and the business and affairs of the corporation shall be controlled by the board.</p>
		<br/><br/>
		<p><h3>2.2. Number, Tenure, Qualifications, and Election.</h3></p>
		<p>The Board of Directors shall consist of 1 person who need not be shareholders of the corporation. The number of directors may be increased or decreased from time to time by approval of the outstanding shares. The directors of the corporation shall be elected at the annual shareholders’ meeting, or at a meeting held in lieu of that meeting as provided in these Bylaws and shall serve until the next succeeding annual meeting and until their successors have been elected and qualified.</p>

		<p><h3>2.3. Board Meetings.</h3></p>
			<p>(a) The Board of Directors shall hold an organizational meeting immediately following each annual shareholders’ meeting. Regular meetings of the Board of Directors shall be held at the times as fixed by a resolution of the board.</p>
			<p>(b) Special meetings of the board may be called at any time by the chairman, chief executive officer, or majority of members of the board.</p>
			<p>(c) Members of the board may participate in a meeting through use of conference telephone, electronic video screen communication, or other communications equipment. Participation in a meeting through use of conference telephone constitutes presence in person at that meeting pursuant to this subsection (c) as long as all members participating in the meeting are able to hear one another. Participation in a meeting through the use of communications equipment other than conference telephone constitutes presence in person at that meeting pursuant to this subsection (c) as long as each member participating in the meeting can communicate with all other participants concurrently; each member may participate in all matters before the board, including proposing or objecting to a specific action to be taken by the corporation; and the corporation verifies prior to the meeting that persons participating in the meeting are entitled to participate, and that any action or vote taken at the meeting is taken only by the directors.</p>

			<p>2.4 Facsimile and Electronic Signatures. Facsimile and electronic signatures of any officer or officers, director or directors of the corporation may be used whenever and as authorized by the Board of Directors or a committee thereof. An “electronic signature” is any electronic symbol or process attached to or logically associated with a document sent by electronic transmission and executed or adopted by a person with the intent to sign such document. “Electronic signature” includes (i) a unique password or unique identification assigned to a person by the corporation; (ii) a person’s typed name attached to or part of an electronic transmission sent by or from a source authorized by such person such as an e-mail address provided by such person as that person’s e-mail address; (iii) a person’s facsimile signature; and (iv) any other form of electronic signature approved by the Board of Directors.</p>

			<p>2.5 Electronic Transmission. “Electronic transmission” or “electronically transmitted” means, for purposes of these Bylaws, any process of communication not directly involving the physical transfer of paper that is suitable for the retention, retrieval, and reproduction of information by the recipient. Notice by electronic transmission is written notice with respect to communications from directors or officers. Notices and written consents may be given by electronic transmission by directors and officers and as otherwise provided in Article 24 (Proxies). Each written consent given by electronic transmission shall contain an electronic signature of the person executing such written consent.
		</p>

		<p><h3>2.6. Quorum and Voting.</h3> A majority of the authorized number of directors shall constitute a quorum for the transaction of business, and the acts of a majority of directors present at a meeting at which a quorum is present shall constitute the acts of the Board of Directors. If at any meeting of the Board of Directors less than a quorum is present, a majority of those present may adjourn the meeting, from time to time, until a quorum is present. If the meeting is adjourned for more than 24 hours, notice of any adjournment to another time or place shall be given prior to the time of the adjourned meeting to the directors who were not present at the time of the adjournment. Every act or decision done or made by the Board of Directors shall be done or made at a meeting called and held as shall be provided by these bylaws.

		</p>
		<br/><br/>
		<p><h3>2.7. Vacancies.</h3> A vacancy in the Board of Directors, except a vacancy occurring by the removal of a director, may be filled by the vote of a majority of the remaining directors, even though less than a quorum is present. Each director so elected shall hold office for the unexpired term of his or her predecessor in office. Any directorship that is to be filled as a result of an increase in the number of directors must be filled by election at an annual or special shareholders’ meeting called for that purpose.
		</p>
		<br/><br/>
		<p><h3>2.8. Indemnification.</h3> The corporation shall indemnify all persons who have served or may serve at any time as officers or directors of the corporation and their heirs, executors, administrators, successors, and assigns, from and against any and all loss and expense, including amounts paid in settlement before or after suit is commenced, and reasonable attorney’s fees, actually and necessarily incurred as a result of any claim, demand, action, proceeding, or judgment that may be asserted against any such persons, or in which these persons are made parties by reason of their being or having been officers or directors of the corporation. This right of indemnification shall not exist in relation to matters as to which it is adjudged in any action, suit, or proceeding that these persons are liable for negligence or misconduct in the performance of duty and in any case the right to indemnification under Section 2.6 of these Bylaws shall be subject to the approval of a majority of disinterested directors.</p>
		<br/><br/>
		<h2>ARTICLE III. OFFICERS</h2>
		<p><h3>3.1. Officers. </h3>The corporation shall have as officers a President, Treasurer, and Secretary (“Principal Officers”). The Board of Directors, in its discretion, may appoint any additional officers it feels are in the best interest of the corporation.</p>
		<p>(a) President.  The President shall be appointed by the Board of Directors, shall be a full time employee of the Corporation retained pursuant to a written contract of employment, shall be an Ex-officio Director and shall be the chief executive officer of the Corporation. He or she shall perform his or her duties in accordance with the policies and directives of the Board of Directors with direct responsibility to the Chairman. He or she shall be charged with the general control and management of the business of the Corporation and shall perform all duties incidental to his or her office. He or she shall keep the Board and the Chairman fully informed and shall freely consult with them concerning the business of the Corporation and from time to time shall make such recommendations regarding the establishment of policies germane to the objectives and business of the Corporation as he or she may deem appropriate.</p>
		<p>(b) Secretary. The Secretary shall keep or cause to be kept an accurate record of proceedings of all meetings of the members of the Corporation and of the Board of Directors, in books belonging to the Corporation, which books shall be kept at the head office of the Corporation and shall be open at all reasonable times to the inspection of any member of the Corporation. He or she shall give or cause to be given notice of all meetings of the members and of the Board of Directors and shall perform such other duties as may be prescribed by the Board or Chairman under whose supervision he or she shall be. He or she shall be the custodian of the seal of the Corporation which he or she shall deliver only when authorized by a resolution of the Board to do so and to such person or persons as may be named in the resolution.</p>
		<p>The Secretary shall be elected by the Board from among the Directors of the Corporation appointed or elected at the first meeting of the Board of Directors subsequent to each election of Directors.</p>
		<p>(c) Treasurer. The Treasurer shall be responsible for the custody of the corporate funds and securities and shall keep or cause to be kept full and accurate accounts of receipts and disbursements in books belonging to the Corporation and shall deposit or cause to be deposited all monies and other valuable effects in the name of and to the credit of the Corporation and in such depositories as may be designated by the Board of Directors from time to time. He or she shall disburse the funds of the Corporation as may be ordered by the Board taking or causing to be taken proper vouchers for such disbursements and shall render or cause to be rendered to the Chairman and Directors at their regular meetings of the Board or whenever they may require it an account of all his or her transactions as Treasurer and of the financial position of the Corporation. He or she shall also perform or cause to be performed such other duties as may from time to time be determined by the Board. </p>
		<br/>
		<p><h3>3.2. Powers and duties.</h3> The powers and duties of the several officers shall be as provided from time to time by resolution or other directive of the Board of Directors. In the absence of such provisions, the respective officers shall have the powers and shall discharge the duties customarily and usually held and performed by like officers of corporations similar in organization and business purposes to this corporation.</p>
		<br/><br/>
		<p><h3>3.3. Election and Term of Office.</h3> The principal officers of the corporation shall be elected by the Board of Directors at its organizational meeting immediately following the annual shareholders’ meeting or as soon after that meeting as practicable. Subordinate officers may be elected from time to time as the board may see fit. Each officer shall hold office until his or her successor is elected and qualified, or until his or her resignation, death, or removal.</p>
		<br/><br/>
		<p><h3>3.4. Removal.</h3> Any officer may be removed from office at any time, with or without cause, on the affirmative vote of a majority of the Board of Directors. Removal shall be without prejudice to any contract rights of the removed officer.</p>
		<br/><br/>
		<p><h3>3.5. Vacancies.</h3> Vacancies in offices, however occasioned, may be filled by election by the Board of Directors at any time for the unexpired terms of such offices.</p>
		<br/><br/>
		<p><h3>3.6. Compensation.</h3></p>
		<p>(a) No salary or other compensation for services as an officer of the corporation shall be paid unless and until that salary or other compensation has been approved by an affirmative vote at a shareholders’ meeting by shareholders owning at least a majority of the outstanding shares of the corporation.</p>
		<p>(b) No bonus or share in the earnings or profits of the corporation shall be paid to any of the officers, directors, or employees of the corporation except pursuant to a plan adopted at a meeting called and held for the purpose by the holders of record of a majority of the outstanding shares of the corporation.</p>
		<p>(c) The officers of the corporation shall be and remain at all times liable to repay to the corporation all amounts paid to them by the corporation as salary or other compensation to the extent these amounts have been disallowed to the corporation as deductions in computing the corporation’s federal income taxes.</p>
		<p>(d) No officer shall be disqualified from receiving a salary by reason of his or her also being a Director of the corporation.</p>
		<br/><br/>
		<h2>ARTICLE IV.  STOCK CERTIFICATES AND SHARE TRANSFERS</h2>
		<p><h3>4.1 Form of Certificate. </h3>The shares of the corporation shall be represented by certificates signed by the Secretary and President. Any or all of the signatures may be facsimile.</p>
		<br/><br/>
		<p><h3>4.2. Transfers.</h3></p>
			<p>(a) All of the issued and outstanding shares of the corporation shall be made subject to restrictions on their transferability by agreement among the holders of the shares. A copy of this agreement shall be kept on file with the corporation’s Secretary, and shall be subject to inspection by shareholders of record and bona fide creditors of the corporation at all reasonable times during business hours.</p>
		<p>(b) Subject to (a) above, transfers of shares of the corporation shall be made in the manner specified in the Uniform Commercial Code. The corporation shall maintain share transfer books, and any transfer shall be registered on those books only on the request and the surrender of the endorsed share certificate representing the transferred shares. The Board of Directors may appoint one or more transfer agents or transfer clerks and one or more registrars as custodians of the transfer book, and may require that all transfers to be made with, and all share certificates to bear the signatures of, any of them. The corporation shall have the absolute right to recognize as the owner of any shares issued by it, for all proper corporate purposes, including the voting of the shares and the issuance and payment of dividends on the shares, the person or persons in whose name the certificate representing the shares stands on its books. However, if a transfer of shares is made solely for the purpose of furnishing collateral security, and if this fact is made known to the Secretary of the corporation, or to the corporation’s transfer agent or transfer clerk, the record entry of the transfer shall state the limited nature of that transfer.</p>
		<br/><br/>
		<h2>ARTICLE V. AMENDMENTS</h2>
		<p><h3>5.1.</h3> These bylaws may be altered, amended or repealed or new bylaws may be adopted by the stockholders or by the Board of Directors, when such power is conferred upon the Board of Directors by the certificate of incorporation at any regular meeting of the stockholders or of the Board of Directors or at any special meeting of the stockholders or of the Board of Directors if notice of such alteration, amendment, repeal or adoption of new bylaws be contained in the notice of such special meeting. If the power to adopt, amend or repeal bylaws is conferred upon the Board of Directors by the certificate of incorporation it shall not divest or limit the power of the stockholders to adopt, amend or repeal bylaws.</p>
		<br/><br/>
		<h3>CERTIFICATE OF SECRETARY OF</h3>
		<h3>{props.corp_name !== undefined && props.corp_name}, A(N) {props.state !== undefined && props.state} CORPORATION</h3>
		<p>The undersigned hereby certifies that he or she is the duly elected and acting Secretary of {props.corp_name !== undefined && props.corp_name}, a(n) {props.state !== undefined && props.state} corporation (the &ldquo;Corporation&rdquo;), and that the Bylaws attached hereto constitute the Bylaws of said Corporation as duly adopted by Action by Written Consent in Lieu of Organizational Meeting by the Directors on {props.qes_start!== undefined && props.qes_start}.</p><br/>
		<p>IN WITNESS WHEREOF, the undersigned has hereunto subscribed his/her name this {props.qes_start!== undefined && props.qes_start}.</p>
		<br/>
		<div >
		<hr />

		<div><p>INPUT!!!, Secretary</p></div></div>  
		</div>
		</>
	);
};

export default BylawsTemplate;