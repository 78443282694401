import React, { useState } from "react";
import { FormControl, Card, CardActions, CardActionArea, CardHeader, CardMedia, CardContent, Typography, Button, Grid, Box, Divider, Alert } from "@mui/material";
import axios from "axios";
import { useLoaderData, useSearchParams, useNavigate, useOutletContext } from "react-router-dom";
import Title from "../../components/Core/Title";
import WhatOthersExperience from "../../components/ContentBlocks/WhatOtherExperience";

export default function Subscriptions(props) {
    const [alert, setAlert] = useState(undefined);
    const loaderData = useLoaderData();
    
    // console.log("subscriptions loader data", loaderData);
    
    const products = loaderData.loader2
        .find(data => data.data["product"] !== undefined)
        .data.product.data.map(p => ({
            ...p, ...{
                product_price: loaderData.loader2
                    .find(data => data.data["product_prices"] !== undefined)
                    .data.product_prices.data.find(pp => pp.product === p.id)
            }
        }
        ));
    function isOdd(num) { return num % 2; }
    const navigateToBilling = () => {
        axios.get("/stripe/create-portal-session/")
            .then(res => window.location.href = res.data.portal_session.url)
            .catch(err => console.log(err))
    }

    // console.log("products", products)

    // console.log("subscription CONTEXT", loaderData.loader0)
    const userContext = loaderData.loader0[0];
    const userLoggedIn = () => {
        if (userContext !== undefined
            && userContext.id !== null
            && userContext.email !== null
            && userContext.email !== undefined
            && userContext.email !== ""
        ) {
            return true;
        }
        return false
    }
    const subscriberContext = loaderData.loader2.find(c => c.data["subscriptions"] !== undefined);
    // console.log("userContext", userContext)
    // console.log("subscriberContext", subscriberContext)

    const creatCheckoutSession = (e) => {
        // console.log("event id", e.target.id);
        const priceId = products.find(p => p.id === e.target.id).product_price.id
        // console.log("priceId", priceId);
        axios.get(`/stripe/create-checkout-session/?priceId=${priceId}`,
        ).catch(error => console.log(error))
            .then(res =>
                window.location.href = res.data.sessionUrl
            )
    }


    return (
        <>
            <Box sx={{ width: {xs: "85%", md: "75%"}, margin: "7.5% auto" }}>
                <Title text={loaderData.loader1.title} />
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    {loaderData.loader2.find(data => data.data["product"] !== undefined) !== undefined && products.map((product, i) =>
                        <Grid
                            item
                            id="CardGridItem"
                            sm={12}
                            md={6}
                            lg={5}
                            p={{xs: 0, md: 2}}
                        >
                            <Card
                                sx={{
                                    border: "none",
                                    borderRadius: "1.5rem 0 1.5rem 0",
                                    padding: "8.5%",
                                    boxShadow: 1,

                                    overflow: "visible",
                                    "&:hover":{
                                        boxShadow: 5,

                                    }
                                }}
                    
                            >
                                <CardHeader
                                    title={product.name}
                                    sx={{
                                        color: isOdd(i) ? "secondary.main" : "primary.main",
                                        textAlign: "center",
                                        borderBottom: "solid 1px",
                                        borderColor: isOdd(i) ? "secondary.main" : "primary.main",
                                    }}
                                >
                                </CardHeader>
                                <CardContent
                                sx={{
                                    borderBottom: "solid 1px",
                                    borderColor: isOdd(i) ? "secondary.main" : "primary.main", 
                                    padding: "10%"
                                   }}>
                                    <Typography
                                        color={isOdd(i) ? "secondary" : "primary"}
                                        variant="body1" dangerouslySetInnerHTML={{ __html: product.description }}
                                        textAlign="center"
                                    >
                                    </Typography>
                                </CardContent>
                                <CardContent>
                                    <Typography
                                        variant="price"
                                        color={isOdd(i) ? "secondary" : "primary"}
                                       sx={{display: "block", width: "100%", fontSize: "2.55em"}}
                                    >
                                        {(product.product_price.unit_amount / 100).toLocaleString("en-US", { style: "currency", currency: "USD" })}
                                    </Typography>
                                    <Typography textAlign="center">per {product.product_price.recurring.interval}</Typography>
                                </CardContent>
                                <CardActions disableSpacing>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={2}
                                       
                                    >
                                        {!userLoggedIn() &&
                                            <Grid item>
                                                <Button
                                                    id={product.id}
                                                    name={product.id}
                                                    color={isOdd(i) ? "secondary" : "primary"}
                                                    variant="contained"
                                                    href="/authentication/registration"
                                                    sx={{marginBottom: {xs: "-90px", sm: "-75px", md: "-100px", }}}
                                                >
                                                    Register
                                                </Button>

                                            </Grid>}
                                        {userLoggedIn() && subscriberContext === undefined &&
                                            <Grid item>
                                                <Button
                                                    id={product.id}
                                                    name={product.id}
                                                    onClick={creatCheckoutSession}
                                                    color={isOdd(i) ? "secondary" : "primary"}
                                                    variant="contained"
                                                    sx={{marginBottom: {xs: "-90px", sm: "-75px", md: "-100px", }}}
                                                >
                                                    Subscribe
                                                </Button>
                                            </Grid>}
                                        {userLoggedIn() && subscriberContext !== undefined && <Grid item>

                                            <Button
                                                id={product.id}
                                                name={product.id}
                                                onClick={navigateToBilling}
                                                color={isOdd(i) ? "secondary" : "primary"}
                                                variant="contained"
                                                sx={{marginBottom: {xs: "-90px", sm: "-75px", md: "-100px", }}}
                                            >
                                                Billing
                                            </Button>
                                        </Grid>}
                                    </Grid>
                                </CardActions>
                            </Card>

                        </Grid>
                        // End Grid Item
                    )}
                </Grid>
                <WhatOthersExperience testimonies={loaderData.loader1.what_others_experience_quote} />
            </Box>
        </>
    )
}
