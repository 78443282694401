import React from "react";
import { Grid, Box, Typography, Divider, Button } from "@mui/material";
import { useLoaderData } from "react-router-dom";
import FullWidthBannerImg from "../../components/Images/FullWidthBannerImg.js";
import NonSubscriberIntro from "../../components/ContentBlocks/NonSubscriberIntro.js";
import ButtonOverImg from "../../components/Buttons/ButtonOverImg.js";

export default function Home() {
  const loaderData = useLoaderData();
  const lastContentBlock =
    loaderData.loader1.home_content_block[
      loaderData.loader1.home_content_block.length - 1
    ];
  // console.log("LOADERDATA!", loaderData);
  return (
    <>

      <Box sx={{ width: { xs: "85%", md: "80%", xl: "65%"}, margin: "2% auto" }}>
      {loaderData.loader1.landing_image !== null && <FullWidthBannerImg
        img_url={loaderData.loader1.landing_image.meta.download_url}
      />}

        <Grid
          container
          spacing={4}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {loaderData.loader1 !== undefined && (
            <Grid item xs={12} id="IntroTextBlock">
              <Grid
                container
                spacing={3}
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                {/* The medium property below defines the width of the 1st text block*/}
                {/* The padding right determines the buffer between text and graphic */}
                <Grid item xs={12} md={9} pr={{xs:"0", md: "12"}}>
                  <Box
                    id="content_block_box"
                    sx={{
                      height: "auto",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <Typography
                        variant="h2"
                        textAlign={{
                          xs: "center",
                          md: "left",
                        }}
                      >
                        {loaderData.loader1.landing_title}
                      </Typography>
                      <Typography
                        variant="h3"
                        mt={{xs: 2, md: 6}}
                        sx={{
                          alignContent: "center",
                          justifyContent: "center",
                          textAlign: { xs: "center", md: "left" },
                          width: "100%",
                        }}
                      >
                        {loaderData.loader1.landing_body}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                {/* The medium property below defines the width of the 1st image block */}
                <Grid item xs={12} md={3}>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={6} md={12}>
                      <Box
                        component="img"
                        sx={{
                          border: "none",
                          // position: "absolute",
                          height: "auto",
                          minWidth: "100%",
                          width: "100%",
                        }}
                        src="static/images/home/line box 1.png"
                      />
                    </Grid>
                    <Grid item xs={6} md={12}>
                      <Box
                        component="img"
                        sx={{
                          border: "none",
                          // position: "absolute",
                          height: "auto",
                          minWidth: "100%",
                          width: "100%",
                        }}
                        src="static/images/home/line box 2.png"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          {loaderData.loader1.home_content_block !== undefined &&
            loaderData.loader1.home_content_block.map((block, i) => (
              <>
                {loaderData.loader1.home_content_block.length - 1 !== i && (
                  <>
                    <Grid
                      item
                      id="IntroGraphicBox"
                      sx={{ display: { xs: "block", sm: "none" } }}
                      xs={12}
                      sm={12}
                      md={block.width}
                      lg={block.width}
                      xl={block.width}
                    >
                      <Box
                        component="img"
                        id="home_bttm_img"
                        sx={{
                          border: "none",
                          // position: "absolute",
                          height: "auto",
                          minWidth: "100%",
                          width: "100%",
                        }}
                        src={block.mobile_image.meta.download_url}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{ display: { xs: "none", sm: "block" } }}
                      xs={12}
                      sm={12}
                      md={block.width}
                      lg={block.width}
                      xl={block.width}
                    >
                      <Box
                        component="img"
                        id="home_bttm_img"
                        sx={{
                          border: "none",
                          // position: "absolute",
                          height: "auto",
                          minWidth: "100%",
                          width: "100%",
                        }}
                        src={block.image.meta.download_url}
                      />
                    </Grid>
                  </>
                )}
              </>
            ))}
          {lastContentBlock !== undefined && (
            <>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box sx={{ position: { xs: "static", sm: "relative" } }}>
                  <Box
                    component="img"
                    id="home_bttm_img"
                    sx={{
                      border: "none",
                      display: { xs: "block", sm: "none" },
                      height: "auto",
                      minWidth: "100%",
                      width: "100%",
                    }}
                    src={lastContentBlock.mobile_image.meta.download_url}
                  />
                  <Box
                    component="img"
                    id="home_bttm_img"
                    sx={{
                      border: "none",
                      display: { xs: "none", sm: "block" },
                      height: "auto",
                      minWidth: "100%",
                      width: "100%",
                    }}
                    src={lastContentBlock.image.meta.download_url}
                  />

                  <Grid
                    container
                    id="ContactUsBox"
                    direction="row"
                    spacing={2}
                    sx={{
                      display: { xs: "none", sm: "block" },
                      position: "absolute",
                      left: "3.18%",
                      bottom: {
                        xs: "none",
                        sm: "10.25%",
                        md: "12.25%",
                        lg: "14.25%",
                        xl: "14.25%",
                      },
                      height: "150px",
                    }}
                  >
                    <Grid item xs={12} sm={7} id="ContactUsItem">
                      <Box
                        sx={{
                          borderRight: { xs: "#154757 solid 6px", xl: "none" },
                          maxWidth: "75%",
                          height: "100%",
                          margin: "0 auto",
                          backgroundImage: `url( 'static/images/buttonBackgrounds/Button Bg.png' )`,
                          backgroundSize: { xs: "cover", xl: "90%" },
                          backgroundPosition: { xl: "center center" },
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <Button
                          // variant="outlined"
                          href="/contact-us"
                          // variant="outlined"
                          sx={{ height: "100%",minHeight: "60px" }}
                          fullWidth={true}
                        >
                          Contact Us
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <Box
                        sx={{
                          borderRight: { xs: "#154757 solid 6px", xl: "none" },
                          maxWidth: "75%",
                          height: "100%",
                          margin: "0 auto",
                          backgroundImage: `url( 'static/images/buttonBackgrounds/Button Bg.png' )`,
                          backgroundSize: { xs: "cover", xl: "90%" },
                          backgroundPosition: { xl: "center center" },
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <Button
                          // variant="outlined"
                          sx={{ height: "100%", minHeight: "60px"}}
                          fullWidth={true}
                          href="/scenarios"
                        >
                          View Scenarios
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} sx={{ display: { xs: "block", sm: "none" } }}>
                <Box sx={{ padding: "8%", border: "black solid 1px" }}>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    sx={{
                      position: "static",
                      bottom: {
                        xs: "none",
                        sm: "10.25%",
                        md: "12.25%",
                        lg: "14.25%",
                        xl: "14.25%",
                      },
                      height: "150px",
                    }}
                  >
                    <Grid item xs={12} sm={7}>
                      <Box
                        sx={{
                          borderRight: { xs: "#154757 solid 6px", xl: "none" },
                          maxWidth: "75%",
                          height: "100%",
                          margin: "0 auto",
                          backgroundImage: `url( 'static/images/buttonBackgrounds/Button Bg.png' )`,
                          backgroundSize: { xs: "cover", xl: "90%" },
                          backgroundPosition: { xl: "center center" },
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <Button
                          // variant="outlined"
                          sx={{ height: "100%" }}
                          fullWidth={true}
                          href="/contact-us"
                        >
                          Contact Us
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <Box
                        sx={{
                          borderRight: { xs: "#154757 solid 6px", xl: "none" },
                          maxWidth: "75%",
                          height: "100%",
                          margin: "0 auto",
                          backgroundImage: `url( 'static/images/buttonBackgrounds/Button Bg.png' )`,
                          backgroundSize: { xs: "cover", xl: "90%" },
                          backgroundPosition: { xl: "center center" },
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <Button
                          // variant="outlined"
                          sx={{ height: "100%" }}
                          fullWidth={true}
                          href="/scenarios"
                        >
                          View Resources
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </>
  );
}
