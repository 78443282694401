import React from 'react';
import Title from "../../components/Core/Title";
import { Grid, Box, Typography, Divider } from "@mui/material";
import FlowerRow from "../../components/Images/FlowerRow";

export default function ProdCancel() {
    return (
        <>
            <Box sx={{width: "75%", margin: "0 auto"}}>
            <Title text="Purchase Cancelled" />
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
                mt={2}
                p={1}
                spacing={4}
                sx={{width: "50%", margin: "0 auto", border: "solid 1px rgba(0,0,0,0.2)"}}
            >
            <Grid item>
                <FlowerRow />
            </Grid>
            <Grid item xs={12} p={2}>
                <Typography variant="body1" textAlign="center">
                <strong>Your purchase did not go through.</strong> You should receive a confirmation email momentarily.
                </Typography>

            </Grid>

            </Grid>
            </Box>
        </>
    )
}