import React from "react";
import * as components from "../../pages/references/References";
import stringSimilarity from "string-similarity";

export default function UseComponent(props) {
    // console.log("use comp", props);
    // const componentNames = Object.keys(components);
    // const matches = stringSimilarity.findBestMatch(props.name, componentNames);
    // const bestMatch = matches.bestMatch.target;
    // console.log('best match', bestMatch);
    const Component = components[props.name];
    return <Component data={props.data !== undefined && props.data}/>
}