import React, { useState } from "react";
import { FormControl, Card, CardActions, CardActionArea, CardHeader, CardMedia, CardContent, Typography, Button, Grid, Box  } from "@mui/material";
import axios from "axios";

import FullWidthBannerImg from "../../components/Images/FullWidthBannerImg";
import { useLoaderData, useSearchParams, useNavigate, useOutletContext } from "react-router-dom";

import NavbarComponent from '../../components/store/Navbar';
import { Container } from 'react-bootstrap';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import ProdCancel from './ProdCancel';
import Store from './store/Store';
import ProdSuccess from './ProdSuccess';
import CartProvider from '../../components/store/src/CartContext';

export default function () {
    const [alert, setAlert] = useState(undefined);
    const loader_data = useLoaderData();
    // console.log("Your Mom",loader_data);
    const products = loader_data.loader2.find(l=>l.data.hasOwnProperty('product')).data.product.data.map(p=>({
        ...p,
       ...{product_price:loader_data.loader2.find(l=>l.data.hasOwnProperty('product_prices')).data.product_prices.data.find(pp=>pp.id===p.default_price)}
        
    }));

    const createCheckoutSession = (event) => {
        axios.get(`/stripe/products/create-checkout-session/?priceId=${event.currentTarget.id}`,
        ).catch(error=>console.log(error))
        .then(res=>{res.data.error != undefined ? setAlert(res.data.error.split(":")[1]):window.location.href= res.data.sessionUrl})
    }

    //.then(res=>{res.data.error != undefined ? setAlert(res.data.error.split(":")[1]):window.location.href= res.data.sessionUrl})
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

 
    return (
        <>
        <CartProvider>
            <NavbarComponent products={products}></NavbarComponent>
            
            <Store products={products} />
        </CartProvider>
        </>
    )
}