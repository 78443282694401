import React from "react";
import VideoTitle from "../../components/Videos/VideoTitle";
import VideoDesc from "../../components/Videos/VideoDesc";
import VideoTags from "../../components/Videos/VideoTags";
import VideoPlayerBox from "../../components/Videos/VideoPlayerBox";
//import RecommendedBox from "../../components/RecommendedBox";
import { Box, Button, Container, Divider, Grid, Icon, Typography, Link } from "@mui/material";
import { useLoaderData } from "react-router-dom";
import { Lock, PlayArrow, PlayCircle } from "@mui/icons-material";

export default function VideoPlayer() {
    const loaderData = useLoaderData()
    const pageContext = loaderData.loader1;
    const subscriberContext = loaderData.loader2.find(c => c.data["subscriptions"] !== undefined);
    const isSubscriber = (subscriberContext !== undefined);

    // console.log("video player loader data", loaderData);
    // console.log("pageContext", pageContext);
    // console.log("subscriberContext", subscriberContext);
    // console.log("isSubscriber", isSubscriber);


    // CMS Variables in pageContext: VideoTitle, VideoDesc, VideoTags, VideoSrc, VideoType, RecommendVideo
    return (

        <>
        <Box sx={{width: {xs: "85%", md: "75%"}, margin: "6% auto 12%"}}>

                <Grid container direction="row" spacing={2}>

                    <Grid item xs={12}>
                        {(isSubscriber && pageContext.requires_subscription || !isSubscriber && !pageContext.requires_subscription) &&
                            <Box sx={{height: "auto"}} id="VideoPlayerContainer">
                                <VideoPlayerBox disabled source={pageContext.video.file} thumbnail={pageContext.video.thumbnail} />
                            </Box>
                        }
                        {!isSubscriber && pageContext.requires_subscription &&
                            <Box sx={{height: "auto"}} id="VideoPlayerContainer">
                            <Box
                            position="relative"
                            display="inline-block"
                            overflow="hidden"
                            width="100%"
                            height="100%"
                            >
                            <img src={pageContext.video.thumbnail} alt="Background" style={{ width: '100%', height: '100%', opacity: .5 }} />
                            <Box sx={{
                                backgroundColor: "background.paper",
                                position: {xs: "static", sm: "absolute"},
                                width: {xs: "100%", sm: "50%"},
                                height: "50%",
                                marginTop: {xs: "5%", sm: 0},
                                top: {xs: 0, sm: '50%'},
                                left: '50%',
                                transform: {xs: "none", sm: 'translate(-50%, -50%)'},
                                padding: {xs: "6%", md: "3%"},
                                borderRadius: "1rem 0 1rem 0"
                            }}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexWrap: 'wrap',
                                marginBottom: {xs: "5%", sm: "10%"},
                            }}>
                            <Icon
                                style={{
                                    fontSize: 60, // Adjust the icon size as needed (default is 100)
                                    color: 'white', // Adjust the icon color as needed
                                    zIndex: "98"
                                }}
                            >
                                <Lock fontSize="inherit" color="secondary" />
                            </Icon>
                            </Box>
                            <Box sx={{
                                width: "100%",
                                height: "50%"
                            }}>
                            <Typography
                                variant="h3"
                                sx={{
                                    textAlign: "center",
                                    fontSize: {xs: "1rem", md: "1.4rem"}

                                }}
                            >
                                Sorry, this video is only for subscribers.
                                Visit <Link href="/subscriptions" color="secondary.main" sx={{textDecoration: "none"}}>Subscriptions</Link>
                            </Typography>
                            </Box>
                            </Box>
                            </Box>
                            </Box>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <VideoTitle text={pageContext.title} />
                    </Grid>
                    <Grid item xs={12}
                    id="Description"
                    px={{xs: 2, md: 0}} 
                    py={{xs: 1, md: 0}} 
                    >
                        <VideoDesc 
                        mb={2}
                        text={pageContext.description} 
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <VideoTags tags={pageContext.tag} />
                    </Grid>
                </Grid>
        </Box>
        </>
    )
}