import React from "react";
import { Navbar } from "react-bootstrap";
import { useTheme } from "@mui/material/styles";
import {
  FormControlLabel,
  Switch,
  Modal,
  Button,
  Typography,
  Box,
  AppBar,
  Collapse,
  IconButton,
  Grid,
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  Chip,
  Icon,
} from "@mui/material";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { useState, useContext } from "react";
import { CartContext } from "./src/CartContext";
import CartProduct from "./CartProduct";
import axios from "axios";
import CSRFToken from "../../loaders/CSRFToken";

import { ShoppingCart, Cancel } from "@mui/icons-material";

// For Filter Dropdown (Temporary Dummy Data)
const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

//   Filter Dropdown Styling
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 45;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

//   Filter Dropdown Functions
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function NavbarComponent(props) {
  console.log("Hey this is Justins console log", props.products);
  // Filter stuff
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };

  // Cart Stuff
  const cart = useContext(CartContext);

  const [show, setShow] = useState(false);
  const [navOpen, setNavOpen] = useState(true);

  const handleNavToggle = () => setNavOpen(!navOpen);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const checkout = async (e) => {
    // console.log("cart", cart);
    const data = cart.items.map((item) => ({
      price: props.products.find((p) => p.id === item.id).product_price.id,
      quantity: item.quantity,
    }));
    await axios
      .post(`/stripe/products/create-checkout-session/`, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRFToken": CSRFToken,
        },
      })
      .catch((error) => console.log(error))
      // .then(res=>console.log(res))
      .then((res) => {
        res.data.error != undefined
          ? setAlert(res.data.error.split(":")[1])
          : (window.location.href = res.data.sessionUrl);
      });
  };

  const productsCount = cart.items.reduce(
    (sum, product) => sum + product.quantity,
    0,
  );

  const prodFilterOptions = props.products.map((item) => ({}));

  return (
    <>
      <AppBar
        position="static"
        sx={{
          borderBottom: "solid 1px rgb(169,169,169)",
          boxShadow: "0",
          background: "none",
          marginTop: { xs: "6px", sm: "24px", lg: "30px" },
        }}
      >
        <Collapse in={navOpen} timeout="auto" unmountOnExit>
          <Grid
            container
            sx={{
              width: "72.5%",
              margin: "0 auto",
              padding: { xs: "2% 0 6%", md: "2% 0 4%" },
            }}
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            {/* /////////////////////// Filter Dropdown */}
            {/* <Grid item xs={12} md={12} lg={4}>
                        <FormControl sx={{ m: 1, width: "100%" }}>
                            <InputLabel id="demo-multiple-chip-label">Filter Category</InputLabel>
                            <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                value={personName}
                                onChange={handleChange}
                                input={<OutlinedInput id="select-multiple-chip" label="Filter Category" />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} />
                                    ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                                >
                                {names.map((name) => (
                                    <MenuItem
                                    key={name}
                                    value={name}
                                    style={getStyles(name, personName, theme)}
                                    >
                                    {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid> */}
            {/* /////////////////////// Cart Button */}
            <Grid item xs={12} md={6} lg={4}>
              <Button
                variant="filledPurple"
                size="lg"
                onClick={handleShow}
                tyle={{ justifyContent: "flex-start" }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item >
                      <Icon >
                        <ShoppingCart sx={{ height: "17px" }} />
                      </Icon>
                  </Grid>
                  <Grid item >
                    Cart ({productsCount} Items)
                </Grid>
                </Grid>
              </Button>
            </Grid>

            {/* <Grid item>
                        <Box p={1}>
                        <IconButton size="sm">
                            <ArrowDropDown onClick={handleNavToggle} />
                        </IconButton>
                        </Box>
                    </Grid> */}
          </Grid>
        </Collapse>
      </AppBar>
      {/* <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    >
                    <Grid item>
                        {!navOpen && <IconButton>
                                <ArrowDropUp onClick={handleNavToggle} />
                </IconButton>}
                </Grid>
            </Grid> */}
      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ width: { xs: "90vw", md: "75vw" }, margin: "5% auto" }}>
          <Box sx={{ backgroundColor: "white" }} p={{ xs: 2, sm: 3, md: 4 }}>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item xs={9} md={11}>
                <Typography id="modal-modal-title" sx={{ fontSize: "1.85rem" }}>
                  Shopping Cart
                </Typography>
              </Grid>
              <Grid item xs={3} md={1}>
                <Button variant="cartClose" onClick={handleClose}>
                  X
                </Button>
              </Grid>
            </Grid>
            <Box mt={2} mb={1}>
              <Typography id="modal-modal-title" variant="caption">
                Items:
              </Typography>
            </Box>
            <Box>
              {productsCount > 0 ? (
                <>
                  <Box
                    id="cartProductTable"
                    py={2}
                    sx={{
                      borderTop: "solid 1px",
                      borderBottom: "solid 1px",
                      borderColor: "primary.main",
                    }}
                  >
                    {cart.items.map((currentProduct, idx) => (
                      <CartProduct
                        key={idx}
                        id={currentProduct.id}
                        quantity={currentProduct.quantity}
                      ></CartProduct>
                    ))}
                  </Box>
                  <Grid
                    container
                    width={1}
                    spacing={{ xs: 2, sm: 0 }}
                    direction="row"
                    sx={{ marginLeft: "0", paddingTop: "4%" }}
                  >
                    <Grid item xs={12} sm={6} ml={0}>
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          height: "100%",
                          alignItems: "center",
                        }}
                      >
                        <Typography alignItems="center">
                          <strong>Total:</strong>{" "}
                          {cart.getTotalCost().toFixed(2)}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Button
                        variant="filledPurple"
                        size="medium"
                        sx={{ display: "block", float: "right" }}
                        preventDefault
                        onClick={checkout}
                      >
                        Purchase Items
                      </Button>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Typography mt={3}>There are no items in your cart!</Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default NavbarComponent;
