import React from "react";
import { Box} from "@mui/material";


export default function FullWidthBannerImg(props) {
    return (
        <>
            <Box
                component="img"
                src={props.img_url}
                sx={{  
                height: {xs: '30vh', sm: '30vh', md: '40vh'},
                width: '100%',
                marginBottom: "3.5%",
                padding: '0',
                objectFit: 'cover',
                objectPosition: {xs: 'left top', md: 'center top'},
            }}
            
            ></Box>
        </>
    )
}