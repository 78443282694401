import React from "react";
import Title from "../../components/Core/Title";
import { Grid, Box, Button, Typography, Divider, List, ListItem, Icon, Container} from "@mui/material";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { useLoaderData } from "react-router-dom";

export default function Services() {
    const loaderData = useLoaderData();
    // console.log("all services ", loaderData)
    const colors = [ '#267976', '#874973', '#874973', '#0e5352' ];
    const dynamicButtons = ['filledGreen', 'filledPurple', 'filledBeige', 'filledGreen']
    const dynamicMargins = [ '0', '20%', '-20%', '0'];

    return (
        <>
            <Container maxWidth="lg">
            <Title text="Services"/>
            <Grid 
            container
            justifyContent="center"
            mt={1}
            py={0}
            px={{xs: 3, md: 0}}>
                <Grid item xs={12} sm={8} md={5}>
                <Typography 
                textAlign="center" 
                variant="h3"
                dangerouslySetInnerHTML={{__html:loaderData.loader1 !== "" && loaderData.loader1.description}}>
                </Typography>
                </Grid>
            </Grid>
            {/* Start S Wreath BG */}
            <Box
            id="SWreathBG"
            component="img"
            my={6}
            src={loaderData.loader1 !== "" && loaderData.loader1.background.meta.download_url}
            sx={{
                position: "absolute",
                top: "39%",
                left: "8.5%",
                width: "78vw",
                height: "auto",
                zIndex: "-1",
                display: {xs: "none", md: "block"}
            }}
            >
            </Box>
            {/* End S Wreath BG */}
            <Grid
            container
            direction="row"
            sx={{
                marginTop: {xs: "0", md: "30%"},
                marginBottom: {xs: "20%", md: "30%"}
            }}
            px={{xs: 0, md: 6}}
            alignItems="flex-start"
            justifyContent="space-evenly"
            spacing={3}
            >
             {loaderData.loader2[0].data !== "" && loaderData.loader2[0].data.items.map((serv, i) =>
            <>
                {/* The 4 Service Cards Start Here:  */}
          
                <Grid 
                item 
                xs={12} 
                md={6}
                sx={{
                    marginTop: {xs: "0", md: dynamicMargins[i]}
                }}
                >

                <Card
                    sx={{
                        border: "1px solid",
                        borderColor: colors[i],
                        backgroundColor: "background.default",
                        borderRadius: "0",
                        padding: {xs: "7.5%", md: "5%"}
                    }}
                    >
                            <CardMedia
                                sx={{height: 300, paddingTop: "4%"}}
                                alt={serv.thumbnail.title}
                                image={serv.thumbnail.meta.download_url}
                            />
                            <CardContent
                            >
                                <Typography 
                                variant="h6" 
                                sx={{fontWeight: "800", fontFamily: "Italiana", paddingTop: "4%"}}
                                component="div" 
                                textAlign="center" 
                                color={colors[i]} >
                                {serv.title !== "" && serv.title}
                                </Typography>
                                <Typography 
                                variant="body1" 
                                textAlign="left" 
                                sx={{padding: "0 4%"}}
                                dangerouslySetInnerHTML={{__html:serv.description !== "" && serv.description}}>
                                </Typography>
                            </CardContent>
                            <CardActions
                             sx={{
                                padding: {xs: "2%", sm: "2% 26%"}
                            }}>
                                <Button variant={dynamicButtons[i]} 
                                href={serv.meta.html_url}>
                                Learn More
                                </Button>
                            </CardActions>
                    </Card>
                </Grid>
            </>
            )}
            </Grid>

            </Container>
        </>
    )
}