import { Typography, Box, Divider, Grid, Icon } from "@mui/material";
import React from "react";
import Title from "../../components/Core/Title";
import FullWidthBannerImg from "../../components/Images/FullWidthBannerImg";
import { useLoaderData } from "react-router-dom";

export default function AboutUs() {
  const loaderData = useLoaderData().loader1;
  // console.log("about us user data", loaderData);
  return (
    <>
      <Box sx={{ width: { xs: "85%", md: "80%", xl: "65%"}, margin: "2% auto" }}>
        {loaderData.landing_image !== null && (
          <FullWidthBannerImg
            img_url={loaderData.landing_image.meta.download_url}
          />
        )}
        <Title text={loaderData.title !== null && loaderData.title} />
        <Grid
          container
          direction={"row"}
          justifyContent="center"
          alignItems="stretch"
          spacing={4}
          px={0}
        >
          {loaderData !== "" &&
            loaderData.about_content_block.length > 0 &&
            loaderData.about_content_block.slice(0, 4).map((cb) => (
              <>
                {cb.image !== "" && cb.image !== null && (
                  <Grid item xs={12} md={cb.width} my={{ xs: 1, md: 4 }}>
                    <Box
                      component="img"
                      sx={{
                        height: "auto",
                        width: "100%",
                        border: "none",
                      }}
                      alt={cb.image.meta.title}
                      src={cb.image.meta.download_url}
                    />
                  </Grid>
                )}
                {cb.body !== "" && cb.body !== null && cb.title !== "" && (
                  <Grid item xs={12} md={cb.width}>
                    <Box width="100%" mx={0} mt={4}>
                      <Typography
                        variant="h5"
                        textAlign={{ xs: "center", md: "left" }}
                      >
                        {cb.title !== undefined &&
                          cb.title !== null &&
                          cb.title}
                      </Typography>
                      <Typography
                        pt={3}
                        variant="body1"
                        textAlign={{ xs: "center", md: "left" }}
                        dangerouslySetInnerHTML={{
                          __html:
                            cb.body !== undefined &&
                            cb.body !== null &&
                            cb.body,
                        }}
                      ></Typography>
                    </Box>
                  </Grid>
                )}

                {cb.body !== "" && cb.body !== null && cb.title == "" && (
                  <Grid item xs={12} md={cb.width}>
                    <Box
                      sx={{ width: { xs: "100%", md: "80%" } }}
                      mx={0}
                      my={0}
                      py={0}
                    >
                      <Typography
                        variant="body1"
                        dangerouslySetInnerHTML={{
                          __html:
                            cb.body !== undefined &&
                            cb.body !== null &&
                            cb.body,
                        }}
                        my={0}
                      ></Typography>
                    </Box>
                  </Grid>
                )}
              </>
            ))}

          <Grid item>
            <Box
              component="img"
              src="static/images/about/FullWidthDivider.png"
              sx={{
                width: "100vw",
                height: "auto",
                border: "none",
              }}
            />
          </Grid>

          {loaderData !== "" &&
            loaderData.about_content_block.length > 4 &&
            loaderData.about_content_block.slice(4).map((cb) => (
              <>
                {cb.image !== "" && cb.image !== null && (
                  <Grid item xs={12} md={cb.width} my={{ xs: 1, md: 4 }}>
                    <Box
                      component="img"
                      sx={{
                        height: "auto",
                        width: "100%",
                        border: "none",
                      }}
                      alt={cb.image.meta.title}
                      src={cb.image.meta.download_url}
                    />
                  </Grid>
                )}
                {cb.body !== "" && cb.body !== null && cb.title !== "" && (
                  <Grid item xs={12} md={cb.width}>
                    <Box width="100%" mx={0} mt={4}>
                      <Typography
                        variant="h5"
                        textAlign={{ xs: "center", md: "left" }}
                      >
                        {cb.title !== undefined &&
                          cb.title !== null &&
                          cb.title}
                      </Typography>
                      <Typography
                        pt={3}
                        variant="body1"
                        textAlign={{ xs: "center", md: "left" }}
                        dangerouslySetInnerHTML={{
                          __html:
                            cb.body !== undefined &&
                            cb.body !== null &&
                            cb.body,
                        }}
                      ></Typography>
                    </Box>
                  </Grid>
                )}

                {cb.body !== "" && cb.body !== null && cb.title == "" && (
                  <Grid item xs={12} md={cb.width}>
                    <Box
                      sx={{ width: { xs: "100%", md: "80%" } }}
                      mx={0}
                      my={0}
                      py={0}
                    >
                      <Typography
                        variant="body1"
                        dangerouslySetInnerHTML={{
                          __html:
                            cb.body !== undefined &&
                            cb.body !== null &&
                            cb.body,
                        }}
                        my={0}
                      ></Typography>
                    </Box>
                  </Grid>
                )}
              </>
            ))}
        </Grid>
      </Box>
    </>
  );
}
