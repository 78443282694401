import React, { useState } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useNavigate } from "react-router-dom";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Avatar } from '@mui/material';
import axios from "axios";
import CSRFToken from "../../loaders/CSRFToken";
import userNavItems from "./UserNavItems";

// When the mobile menu is expanded to partially overlay the page, this variable controls the width in pixels
const drawerWidth = 250;

export default function DrawerAppBar(props) {
  // console.log("header props", props);
  const { containerWindow } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [itemSelected, setItemSelected] = useState(false);
  const navigate = useNavigate();

  const cleanPages = (pages) => {
    const pagePath = (page) => {
      const url = page.meta.html_url.split("/");
      let path = "";
      const level =  url.length-4;
      for (let i = 0; i < url.length-3; i++) {
        path = path + `/${url[i+3]}`
      }
      return {path: path, level: level};
    };
    const cleanPages = pages.items.filter(page => pagePath(page).level === 0 && page.meta.show_in_menus === true).map(page => (
      {
        title: page.title,
        path: pagePath(page).path,
        level: pagePath(page).level,  
        children: pages.items.filter(
          subpage => pagePath(subpage).level === 1 
            && pagePath(page).path !== "/"
            && pagePath(subpage).path.includes(pagePath(page).path)
            && subpage.meta.show_in_menus === true
        ).map(subpage => ({
          title: subpage.title,
          path: pagePath(subpage).path,
          level: pagePath(subpage).level
        }))
   
      })
      );

    return cleanPages;
  }

  // console.log("props.context", props.context)

  

// console.log("windows location pathname: ", window.location.pathname)

  // console.log("props context find", props.context.find(c => Array.isArray(c.data) && c.data["error"] === undefined))
  const userContext = props.context.find(c => 
    Array.isArray(c.data) && c.data["error"] === undefined) !== undefined 
      ? props.context.find(c => Array.isArray(c.data) && c.data["error"] === undefined).data[0] 
      : undefined;
  // console.log("HEADER USER CONTEXT", userContext);
  const subscriberContext = props.context.find(c => c.data["subscriptions"] !== undefined);
  // console.log("userContext", userContext)
  // console.log("subscriberContext", subscriberContext)

  const subscriberLoggedIn = () => {
    if (subscriberContext !== undefined) {
        return true;
      }
      return false
  }
  const userLoggedIn = () => {
    if (userContext !== undefined
      && userContext.id !== null
      && userContext.email !== null
      && userContext.email !== undefined
      && userContext.email !== ""
      ) {
      return true;
    }
    return false
  }
  
  const newUser = () => {
    return userLoggedIn() !== true ? true : false
  }
  // console.log("newUser", newUser())

  const handleUserMenu = (e) => {
    if (e.currentTarget.id === "Billing") {
      axios.get("/stripe/create-portal-session/")
        .then(res => window.location.href = res.data.portal_session.url)
        .catch(err => console.log(err))
    }
    if (e.currentTarget.id === "Logout"){
      axios.post("/dj-rest-auth/logout/",
      {},
      {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRFToken
        }
    }
      )
        .then(res => window.location.href = "/")
        .catch(err => console.log(err))
    }
  }
  

  const headerPages = cleanPages(props.context[0].data);
  // console.log("headerPages", headerPages);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleItemSelected = (event) => {
    const page = [...headerPages,...userNavItems].find((page) => page.title === event.currentTarget.id);
    page.children.length > 0 ?
      (itemSelected !== event.currentTarget ? setItemSelected(event.currentTarget) : setItemSelected(false))
      : navigate(page.path)
  }


  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const drawer = (
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
      {headerPages[0].title}
      </Typography>
      <Divider />
      <List>
        {headerPages.map((parentItem, index) => (
          <>
            <ListItem sx={{ borderBottom: 1, borderColor: 'primary.main', textAlign: 'center' }} key={index !== 0 ? parentItem.title : "Home"} name={index !== 0 ? parentItem.title : "Home"} id={index !== 0 ? parentItem.title : "Home"} disablePadding>
              <ListItemButton onClick={handleItemSelected} name={index !== 0 ? parentItem.title : "Home"} id={index !== 0 ? parentItem.title : "Home"}>
                <ListItemText primary={index !== 0 ? parentItem.title : "Home"} sx={{ fontWeight: 'bold', color: 'primary.main' }} />
                {parentItem.children.length > 0 && <IconButton onClick={handleItemSelected} name={index !== 0 ? parentItem.title : "Home"} id={index !== 0 ? parentItem.title : "Home"}><ArrowDropDownIcon /></IconButton>}
              </ListItemButton>
            </ListItem>
            {parentItem.children.length > 0 && itemSelected.id === parentItem.title && parentItem.children.map((childItem) => (
              <List name={childItem.title}>
                <ListItem id={childItem.title} name={childItem.title} key={childItem.title} disablePadding>
                  <ListItemButton sx={{ textAlign: 'center' }} href={childItem.path}>
                    <ListItemText primary={childItem.title} />
                  </ListItemButton>
                </ListItem>
              </List>
            ))}
          </>
        ))}
      </List>
    </Box>
  );

  const container = containerWindow !== undefined ? () => containerWindow().document.body : undefined;

  return (
    <>
      <CssBaseline />
      {/* ============================ Normal Menu ============================ */}
    <Box component="span" sx={{
        // position: "fixed", 
        top: "0", 
        width: "100%", 
        borderBottom: "solid 1px rgba(0, 0, 0, 0.2)",
        zIndex: "99"
    }}>

          {/* This Box adds left + right padding to the entire Nav, and should probably align with the page box width, unless you're doing something fancy */}

          <Box
            sx={{width: { xs: "85%", md: "80%", xl: "65%"}, margin: "0 auto",}}
          >

          {/* This grid container adds top margin, to nudge the nav away from the browser's top edge */}
                    {/* It also helps define the nav's height */}
          <Grid 
            container id="NavPaddingSidesAndTop"
            alignContent="flex-end"
            mt={2}
          >

            <Grid item xs={10} md={3}>
              <Grid container id="LogoGridContainer" sx={{
                width: "100%",
                height: "100%",

              }}
              justifyContent="center"
              >
              <Grid item id="LogoGridItem" xs={12}>
              {/* The height setting inside this LogoButton variant helps determine the height of the whole NAV ! */}
              <Button variant="LogoButton"
               href={headerPages[0].path}
               sx={{
                paddingLeft: {
                xs: 0,
                },
                backgroundImage: 
                {xs: 'url("../static/images/FinalMobileLogo.png")',
                md: 'url("../static/images/FinalLogoWebsiteReady.png")'
                }
               }}
               >
                
                {/* {headerPages[0].title} */}
              </Button>
              </Grid>
              </Grid>
            </Grid>
            <Grid 
            item 
            xs={2} 
            sx={{display: { xs: 'block', md: 'none'} }}>
            <Grid container
            justifyContent="flex-end"
            alignItems="center"
            direction="row"
            sx={{
              height: "100%",
            }}
            >
              <Grid item xs={12}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerToggle}
                name="hamburgerButton"
                sx={{
                  display: "block",
                  padding: 0,
                  margin: 0,
                  width: "100%",
                  height: "100%",
                  textAlign: "right",
                  paddingTop: "5px",
                }}
              >
              <MenuIcon fontSize="large" name="hamburgerIcon"/>
              </IconButton>
              </Grid>
              </Grid>
            </Grid>
            <Grid item 
                  md={9} 
                  sx={{ 
                    display: 
                    { xs: 'none', sm: 'none', md: 'block' }
                    }}>
              <Grid
                container
                sx={{height: "100%"}}
                direction="row"
                justifyContent="flex-end"
                flexWrap="nowrap"
                columnSpacing={0.5}
                rowSpacing={0}
              >
              {headerPages.slice(1).map((parentItem) => (
                <Grid item>
                <Button
                variant={window.location.pathname.includes(parentItem.path) ? "activeNavButton" : "inactiveNavButton"}
                  onClick={handleItemSelected} name={parentItem.title} id={parentItem.title}>
                    <ListItemText
                    primary={parentItem.title} />
                  </Button>
                  {parentItem.children.length > 0 && itemSelected.id === parentItem.title && parentItem.children.map((childItem) => (

                    <>
                      <Popper
                        open={open}
                        anchorEl={itemSelected}
                        role={undefined}
                        placement="bottom-start"
                        transition
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin:
                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                            }}
                          >

                            <Paper sx={{borderRadius: "0", boxShadow: "0", border: "solid 1px rgb(169,169,169)"}}>
                              <ClickAwayListener onClickAway={handleItemSelected}>
                                <MenuList
                                  autoFocusItem={open}
                                  id="composition-menu"
                                  aria-labelledby="composition-button"
                                  onKeyDown={handleListKeyDown}
                                  sx={{
                                    padding: "0",
                                  }}

                                >
                                  {parentItem.children.length > 0 && parentItem.children.map(childItem =>
                                    <MenuItem
                                    sx={{
                                      minWidth: "220px",
                                      padding: "0"
                                    }}>

                                      <Button href={childItem.path}
                                      sx={{
                                        color: 'text.primary',
                                        fontWeight: '400',
                                        display: "block",
                                        height: "100%",
                                        width: "100%",
                                        borderRadius: "0",
                                        textTransform: "none",
                                        fontSize: "1rem",
                                        padding: "8%"
                                    }}
                                      >
                                      
                                        {childItem.title}
                                      </Button>

                                    </MenuItem>
                                  )}
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </>
                  ))}
                </Grid>
              ))}
              {/* <Grid item>
                <Button 
                onClick={handleItemSelected} 
                id={"UserMenu"} 
                key={"UserMenu"} 
                name={"UserMenu"}
                sx={{
                  display: 'block',
                  minHeight: '100%',
                  width: '100%',
                  color: 'text.primary',
                  fontWeight: '400',
                  fontSize: '1rem',
                  lineHeight: "2.4rem",
                  // paddingTop: "28px",
                  // paddingBottom: "28px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  borderRadius: "0"
                }}
                >
                  <Avatar sx={{height: "30px", width: "30px"}}></Avatar>
                  </Button>
                {itemSelected.id === "UserMenu" &&

                  <>
                    <Popper
                      open={open}
                      anchorEl={itemSelected}
                      role={undefined}
                      placement="bottom-start"
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === 'bottom-start' ? 'left top' : 'left bottom',
                          }}
                        >

                          <Paper
                          sx={{borderRadius: "0", boxShadow: "0", border: "solid 1px rgb(169,169,169)"}}
                          >
                            <ClickAwayListener onClickAway={handleItemSelected}>
                              <MenuList
                                autoFocusItem={open}
                                id="composition-menu"
                                aria-labelledby="composition-button"
                                onKeyDown={handleListKeyDown}
                                sx={{padding: "0"}}
                              >
                              {userLoggedIn() &&
                                <MenuItem
                                  sx={{minWidth: "240px", width: "auto", padding: "0"}}
                                  disableRipple
                                  disableTouchRipple
                                  >
                                  <Typography sx={{                                      
                                      display: "block",
                                      fontWeight: '300',
                                      height: "100%",
                                      width: "100%",
                                      borderRadius: "0",
                                      textTransform: "none",
                                      fontSize: "1rem",
                                      padding: "8%",
                                      overflowX: "hidden",
                                      textAlign: "center"}}
                                      color="secondary">
                                    {userContext.username}
                                  </Typography>
                                  </MenuItem> 
                                }
                                {userNavItems[0].children
                                  .filter(userPage =>
                                      (subscriberLoggedIn() === true && userPage.login_required !== true && userPage.subscriber_required === true)
                                      || (userLoggedIn() === true && userPage.subscriber_required !== true && userPage.login_required === true)
                                      || (subscriberLoggedIn() === true && userLoggedIn() === true && userPage.subscriber_required === true && userPage.login_required === true)
                                      || (newUser() === true && userPage.new_user === true)
                                      )
                                .map(childItem =>
                                  <MenuItem
                                  sx={{minWidth: "220px", padding: "0"}}
                                  >
                                    <Button 
                                    href={childItem.path !== "" && childItem.path}
                                    onClick={childItem.path === "" && handleUserMenu}
                                    id={childItem.title}
                                    name={childItem.title}
                                    sx={{
                                      display: "block",
                                      color: 'text.primary',
                                      fontWeight: '300',
                                      height: "100%",
                                      width: "100%",
                                      borderRadius: "0",
                                      textTransform: "none",
                                      fontSize: "1rem",
                                      padding: "8%",
                                      textAlign: "center"

                                      }}>
                                      {childItem.title}
                                    </Button>
                                  </MenuItem>
                                )}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </>
                }

              </Grid> */}
            </Grid>

          </Grid>
          </Grid>
          </Box>
   {/* End Grid Container for the entire nav */}
      </Box>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block'},
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      {/* ============================ Normal Menu End ============================ */}
    </>
  );
}
