import {
  Typography,
  Box,
  Button,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
} from "@mui/material";
import React, { useState } from "react";
import Title from "../../components/Core/Title";
import FullWidthBannerImg from "../../components/Images/FullWidthBannerImg";
import { useLoaderData, useNavigate } from "react-router-dom";
import SearchBar from "../../components/Inputs/SearchBar";

export default function ScenariosSelection() {
  const loaderData = useLoaderData();
  // console.log("loaderData", loaderData);
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");

  // Function to strip HTML tags from a given HTML string
  const stripHtmlTags = (html) => {
    // Create a temporary DOM element
    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;

    // Extract text content from the element (which removes HTML tags)
    const text = tempElement.textContent || tempElement.innerText || '';

    return text;
  };

  const flattenContentBlocks = (dataList) => {
    return dataList.flatMap((scenario) => {
      const { id, meta, title, thumbnail, short_description, scenario_content_block } = scenario;
  
      if (!scenario_content_block || scenario_content_block.length === 0) {
        // If scenario_content_block is empty or undefined, return the scenario as-is
        return [{ id, meta, title, thumbnail, short_description, scenario_content_block }];
      }
  
      return scenario_content_block.map((contentBlock) => {
        const { id: contentBlockId, meta: contentBlockMeta, title: contentBlockTitle, body, image, mobile_image, width } = contentBlock;
  
        return {
          id,
          meta,
          title,
          thumbnail,
          short_description: stripHtmlTags(short_description),
          scenario_content_block: undefined, // Remove original scenario_content_block from nested objects
          contentBlockId,
          contentBlockMeta,
          contentBlockTitle,
          body: stripHtmlTags(body),
          image,
          mobile_image,
          width
        };
      });
    });
  };

  // console.log("flattenContentBlocks(loaderData.loader2.items)", flattenContentBlocks(loaderData.loader2.items));
  const handleOnSelect = (e) => {
    // console.log("event from handleOnSelect", e);
    window.location.href = e.meta.html_url;
  }

  return (
    <>
      <Box
        sx={{ width: { xs: "85%", md: "80%", xl: "65%" }, margin: "2% auto" }}
      >
        {loaderData.loader1.landing_image !== null && (
          <FullWidthBannerImg
            img_url={loaderData.loader1.landing_image.meta.download_url}
          />
        )}
        <Title
          text={loaderData.loader1.title !== null && loaderData.loader1.title}
        />
        <Grid
          container
          direction={"row"}
          justifyContent="center"
          alignItems="center"
          spacing={4}
          px={0}
        >
          {/* Grid item for filters */}
          {/* <Grid item xs={12}>
            <Grid
              container
              direction={"row"}
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={12}>
                <FormControl variant="standard" sx={{ minWidth: "100%", width: "100%", borderRadius: "0"}}>
                  <SearchBar
                    items={
                      loaderData.loader2 !== undefined &&
                      loaderData.loader2.items.length > 0 &&
                      flattenContentBlocks(loaderData.loader2.items)
                      }
                    onSearchSelected={handleOnSelect}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid> */}
          {/* Grid item for selection */}
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="stretch"
              spacing={2}
            >
              {loaderData.loader2 !== undefined &&
                loaderData.loader2.items.length > 0 &&
                loaderData.loader2.items
                  .map((scenario) => (
                    <>
                      <Grid item xs={12} md={6}>
                        <Button href={scenario.meta.html_url} 
                        sx={{
                          display: "block", 
                          minWidth: "100%",
                          "&:hover":{
                                        boxShadow: 4,

                                    }

                          }}>
                          <Card name={scenario.id} value={scenario.id} sx={{minWidth: "100%", minHeight: "280px"}}>
                            { scenario.thumbnail !== null && <CardMedia
                              id={scenario.id}
                              key={scenario.id}
                              name={scenario.id}
                              value={scenario.id}
                              component="img"
                              height="200"
                              image={scenario.thumbnail.meta.download_url}
                              alt={scenario.title}
                            /> }
                            
                            <CardContent sx={{height: "120px", minWidth: "100%"}}>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                                sx={{ textAlign: "center", minWidth: "100%" }}
                              >
                                {scenario.title}
                              </Typography>
                              {/* We don't use "shortdescription" for Scenario Categories anymore, so the code below is unnecessary and resulting in a large empty space */}
                              {/* 
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{ textAlign: "center", height: "120px", minWidth: "100%", overflow: "hidden"}}
                                dangerouslySetInnerHTML={{
                                  __html: scenario.short_description.length > 180 ? scenario.short_description.substring(0, 177) + "..." : scenario.short_description,
                                }}
                              />
                              */}
                            </CardContent> 
                          </Card>
                        </Button>
                      </Grid>
                    </>
                  ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
