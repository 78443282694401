import React from "react";
import axios from "axios";

function getProducts() {
    let response = [];
    const res = axios.all([
            axios.get("/stripe/products/?type=ecommerce"),
            axios.get("/stripe/prices/")
    ])
    .then((res2) => {
        // console.log("res2", res2)
        return res2.find(r=>r.data.hasOwnProperty('product')).data.product.data.map(p=>({
            ...p,
            ...{product_price: res2.find(r=>r.data.hasOwnProperty('product_prices')).data.product_prices.data.find(pp=>pp.id===p.default_price)}
        }))
    })
    return res;
}

const productsArray = await getProducts()

function getProductData(id) {
    // console.log("products array", productsArray)
    let productData = productsArray.find(product => product.id === id);

    if (productData == undefined) {
        // console.log("Product data does not exist for ID: " + id);
        return undefined;
    }

    return productData;
}

export { productsArray, getProductData };