import React from "react";
import { Grid, Box, Typography, Divider, Button} from "@mui/material";

export default function NonSubscriberIntro (props) {
    return (
        <>
            
            <Grid container
            name="IntroGridContainer"
               direction={"row"}
               alignItems="stretch"
               justifyContent="space-between"
               spacing={{xs: 3, lg: 0.5}}
               m={0}
               mt={4}
               p={0}
               pl={{xs: 3, md: 0}}
               

               
                           > 
{/* LEFT BOX ---------------------------------------- */}
<Grid item xs={12} sm={3.75} mt={{xs: 4, lg: 0}} sx={{ backgroundColor: 'background.default', border: '1px solid black', borderRadius: '1.5em 0 0 1.5em', padding: '0 !important'}}>
                        <Grid container direction="column" alignItems="stretch">
                       <Grid item xs={12} lg={2} justifyContent="center" alignItems="center" sx={{padding: "8% 25%"}}>
                        <Box
                        component="img"
                            sx={{
                   
                                maxWidth: '100%',
                                height: 'auto',
                               padding: '0',
                               maxHeight: '15%',
                                objectFit: 'cover',
                             
                        }}
                        alt="The house from the offer."
                        src="/static/images/explanationIcon1.png"
                        >
                        </Box>
                        </Grid>
                            <Grid item xs={12} lg={7} alignItems="space-evenly">
                    <Divider sx={{bgcolor: 'secondary.main', width: "80%", margin: "0 auto"}}/>
  
                        <Box sx={{padding: "5% 20%", margin: "0 auto"}}> 
                        
                            <Typography variant="body1" color="secondary.main" textAlign="center" sx={{fontSize: "1.3rem", fontWeight: "400"}}>
                            We are thrilled to share our introductory videos, free of charge, to anyone and everyone.
                        </Typography>
                        </Box>
                        </Grid>
                        <Grid item xs={12} lg={3}>
      
                        </Grid>
                        </Grid>
                        </Grid>  
  {/* MIDDLE BOX ---------------------------------------- */}
  <Grid item xs={12} sm={3.75} mt={{xs: 4, lg: 0}} sx={{ backgroundColor: 'background.default', border: '1px solid black', borderRadius: '1.4em 0 0 1.5em', padding: '0 !important'}}>
                        <Grid container direction="column">
                        <Grid item xs={12} lg={2} justifyContent="center" alignItems="center" sx={{padding: "8% 25%"}}>
                        <Box
                        component="img"
                            sx={{
                            maxWidth: '100%',
                            height: 'auto',
                            padding: '0',
                            maxHeight: '15%',
                            objectFit: 'cover',
                        }}
                        alt="The house from the offer."
                        src="/static/images/explanationIcon2.png"
                        >
                        </Box>
                        </Grid>
                            <Grid item xs={12} lg={7} alignItems="space-evenly">
                    <Divider sx={{bgcolor: 'primary.main', width: "80%", margin: "0 auto"}}/>
                        <Box sx={{padding: "5% 20%", margin: "0 auto"}}> 
                            <Typography variant="body1" color="primary.main" textAlign="center" sx={{fontSize: "1.3rem", fontWeight: "400"}}>
                            If you want to dive deeper into our content, check out our subscription plans.
                        </Typography>
                        </Box>
                        </Grid>
                        <Grid item xs={12} lg={3} sx={{padding: "5% 25% 15%"}}>
                            <Button target="_blank" variant="filledGreen" href="/subscriptions">View Plans</Button>
                        </Grid>
                        </Grid>
                        </Grid>
  {/* RIGHT BOX ---------------------------------------- */}
  <Grid item xs={12} sm={3.75} mt={{xs: 4, lg: 0}} sx={{ backgroundColor: 'background.default', border: '1px solid black', borderRadius: '1.5em 0 0 1.5em', padding: '0 !important'}}>
                        <Grid container direction="column">
                        <Grid item xs={12} lg={2} justifyContent="center" alignItems="center" sx={{padding: "8% 25%"}}>
                        <Box
                        component="img"
                            sx={{
                            maxWidth: '100%',
                            height: 'auto',
                            padding: '0',
                            maxHeight: '15%',
                            objectFit: 'cover',
                        }}
                        alt="The house from the offer."
                        src="/static/images/explanationIcon3.png"
                        >
                        </Box>
                        </Grid>
                            <Grid item sm={12} lg={7} alignItems="space-evenly">
                    <Divider sx={{bgcolor: 'secondary.main', width: "80%", margin: "0 auto"}}/>
  
                        <Box sx={{padding: "5% 15%", margin: "0 auto"}}> 
                        
                            <Typography variant="body1" color="beige" textAlign="center" sx={{fontSize: "1.3rem", fontWeight: "400"}}>
                            Each subscription includes access to new videos added to the library over time.
                        </Typography>
                        </Box>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                        </Grid>
                        </Grid>
                        </Grid>    
                     {/* Close Grid container */}
                </Grid>
            
              

                </>
    )
}