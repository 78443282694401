import React from "react";
import axios from "axios";

export default function UserLoader() {
    const res = axios.get(`/api/users/current/`)
      .catch(error => {
        // console.log("get user error", error);
      })
      .then(response => {
        return response.data
      })
      ;
    return res;
}