import React from "react";
import axios from "axios";

export default function ServiceSelectionLoader() {
  let response = [];
  const res = axios.all([
    axios.get("/api/v2/pages/?type=services.Service&fields=thumbnail,description,scenario_content_block")
      .catch(error => console.log(error))
      .then(res => { 
        response.push(res);
        axios.get("/stripe/subscriptions/")
        .catch(error => console.log(error))
        .then(res => { response.push(res) }),
        axios.get("/api/users/current/")
        .catch(error => console.log(error))
        .then(res => { response.push(res) })
       }),

  ]).then(() => { return response })
  return res;
}