import React from "react";
import axios from "axios";

export default function ScenarioSelectionLoader() {
  const res = axios.get("/api/v2/pages/?type=scenarios.Scenario&fields=thumbnail,short_description,scenario_content_block")
  .catch(error => {
    // console.log("service selection loader error", error);
  })
  .then(response => {
    return response.data
  })
  ;
  return res;
}