import React from 'react';
import {Button, Box, Typography, Grid} from "@mui/material";
import { CartContext } from './src/CartContext';
import { useContext } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';

function ProductCard(props) { // props.product is the product we are selling
    const product = props.product;
    const cart = useContext(CartContext);
    const productQuantity = cart.getProductQuantity(product.id);
    // console.log(cart.items);
    return (
        <Box sx={{width: "100%"}}>

                        <Box component="form" noValidate my={1} sx={{ width: "100%" }}>
                        <Grid container direction="row" flexWrap={"nowrap"}>
                            <Grid item xs={5} sm={6}>
                                <Box  sx={{display: "flex", height: "100%", alignItems: "center"}}>
                                    <Typography variant="body1"><strong>Quantity: {productQuantity}</strong></Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={7} sm={6}>
                                <Grid container direction="row" spacing={0.5} justifyContent={"flex-end"} flexWrap={'nowrap'}>
                                    <Grid item xs={6} p={0}>
                                    <Button variant="cartOutside+-" onClick={() => cart.addOneToCart(product.id)}><span id="initialSpan"><AddCircleIcon /></span><span id="hoverSpan"><AddCircleOutlineIcon /></span></Button>    
                                    </Grid>
                                    <Grid item xs={6} p={0}>
                                    <Button variant="cartOutside+-"  onClick={() => cart.removeOneFromCart(product.id)}><span id="initialSpan"><RemoveCircleIcon /></span><span id="hoverSpan"><RemoveCircleOutlineIcon /></span></Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        </Box>
                        { productQuantity > 0 ?
                    <>
                        <Button variant="cartOutsideChange" sx={{display: "block", width: "100%"}} onClick={() => cart.deleteFromCart(product.id)}>Remove From Cart<span><CancelIcon /></span></Button>
                    </>
                    :
                    <Button variant="cartOutsideChange" sx={{display: "block", width: "100%"}} onClick={() => cart.addOneToCart(product.id)}>   
                     Add to Cart<span><AddCircleIcon /></span></Button>
                }
        </Box>
    )
}

export default ProductCard;