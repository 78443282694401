import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import axios from "axios";
import { Alert } from '@mui/material';
import { useLoaderData, useNavigate } from "react-router-dom";
import CSRFToken from '../../loaders/CSRFToken';

export default function Login() {
    const [alert, setAlert] = useState(undefined);
    const [freezeForm, setFreezeForm] = useState(false);
    const loaderData = useLoaderData();
    // console.log("my account loader data", loaderData);
    const [firstName, setFirstName] = useState(loaderData[0].first_name);
    const [lastName, setLastName] = useState(loaderData[0].last_name);
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // console.log("put data", data);
        axios.put(
            "/dj-rest-auth/user/",
            data,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': CSRFToken
                }
            }
        )
            .then(response => {
                // console.log(response);
                alert.type = "success";
                setAlert({ message: "Successfully logged in." });
                setFreezeForm(true);
            })
            .catch(error => {
                // console.log(error);
                error.response.data.type = "error"
                setAlert(error.response.data);
                setFreezeForm(false);
            })
            .finally(() => setTimeout(() => {
                setFreezeForm(false);
                setAlert(undefined);
            }, 8000))
    };

    return (
        <>
            <Typography component="h1" variant="h5">
                My Account
            </Typography>
            <Typography variant="body1">
                Welcome {loaderData[0].username}!
            </Typography>
        </>
    );
}