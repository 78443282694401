import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "../pages/app/App";
import PageLoader from "../loaders/PageLoader";
import UseComponent from "../components/Functions/UseComponent";
import PageDetailLoader from "../loaders/PageDetailLoader";
import AuthPages from "../pages/references/AuthPages";
import StripeStatusPages from "../pages/references/StripeStatusPages";
import useFunction from "../components/Functions/UseFunction";
import UserLoader from "../loaders/UserLoader";
import { json } from "react-router-dom";
import PageNotFound from "../pages/functional/PageNotFound";

const pages = await PageLoader();

const cmsPages = (pages) => {
  // console.log("PAGES", pages);

  const pagePath = (page) => {
    // console.log("html url", page.meta.html_url)
    const url = page.meta.html_url.split("/");
    let path = "";
    const level = url.length - 4;
    for (let i = 0; i < url.length - 3; i++) {
      path = path + `/${url[i + 3]}`;
    }
    return { path: path, level: level };
  };

  const pageLoader = async (page) => {
    // console.log("page loader", `${page.meta.type.split(".")[1]}Loader`);
    const [loader0, loader1, loader2] = await Promise.all([
      UserLoader(),
      PageDetailLoader(page.meta.detail_url),
      useFunction(`${page.meta.type.split(".")[1]}Loader`),
    ]);
    return json({ loader0, loader1, loader2 });
  };

  const routerPages = [
    ...pages.items
      .filter((p) => p.meta.type !== "footer.Footer")
      .filter((page) => pagePath(page).level === 0)
      .map((page) => ({
        level: pagePath(page).level,
        path: pagePath(page).path,
        loader: () => pageLoader(page),
        element: (
          <UseComponent name={page.meta.type.split(".")[1]} data={page} />
        ),
      })),
      ...pages.items
      .filter((p) => p.meta.type !== "footer.Footer")
      .filter(
        (subpage) =>
          pagePath(subpage).level === 1 &&
          pagePath(subpage).path !== "/" &&
          pagePath(subpage).path.includes(pagePath(subpage).path),
      )
      .map((subpage) => ({
        level: pagePath(subpage).level,
        path: pagePath(subpage).path,
        loader: () => pageLoader(subpage),
        element: (
          <UseComponent name={subpage.meta.type.split(".")[1]} data={subpage} />
        ),
      })),
  ];
  // console.log("NEW PAGES", routerPages);
  return routerPages;
};

const pageNotFound = {
  path: "*",
  element: <PageNotFound />,
};

const router = createBrowserRouter([
  {
    element: <App />,
    loader: () => pages,
    children: [
      ...cmsPages(pages[0].data),
      ...AuthPages,
      ...StripeStatusPages,
      pageNotFound,
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />,
);
