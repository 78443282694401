import React from "react";
import { default as HomeRef} from "../home/Home";
import { default as HomeVideoRef} from "../home/Home"
import {default as FooterRef} from "../../components/Core/Footer";
import { default as AboutUsRef} from "../about_us/AboutUs";
import { default as ServicesRef} from "../services/Services";
import { default as ServiceRef} from "../services/Details";
import { default as VideosRef} from "../videos/Videos";
import { default as VideoRef} from "../videos/VideoPlayer";
import {default as LandingPageRef} from "../landing_page/LandingPage";
import { default as ProductsRef } from "../products/Products";
import {default as SubscriptionsRef} from "../subscription/Subscriptions";
// import {default as ProductsRef} from "../products/Products";
import {default as ContactUsRef} from "../contact_us/ContactUs";
import {default as ResourcesRef} from "../resources/Resources";
import {default as PDFTemplateRef} from "../pdf_lib/PDFTemplate";
import {default as ScenarioSelectionRef} from "../scenarios/ScenarioSelection";
import {default as ScenarioRef} from "../scenarios/Scenario";
import {default as PageNotFoundRef} from "../functional/PageNotFound";

export const Home = HomeRef;
export const HomeVideo = HomeVideoRef;
export const Footer = FooterRef;

export const LandingPage = LandingPageRef;

export const AboutUs = AboutUsRef;
export const ContactUs = ContactUsRef;

export const Products =  ProductsRef;

export const Subscriptions = SubscriptionsRef;

export const ServiceSelection = ServicesRef;
export const Service = ServiceRef;

export const VideoSelection = VideosRef;
export const Video = VideoRef;
export const Resources = ResourcesRef;

export const Scenario = ScenarioRef;
export const ScenarioSelection = ScenarioSelectionRef;

export const PDFTemplate = PDFTemplateRef;

