import React, { useState } from 'react';
// import Button from '@mui/material/Button';
// import TextField from '@mui/material/TextField';
// import { Alert } from '@mui/material';
// import Grid from '@mui/material/Grid';
// import Link from '@mui/material/Link';
// import Typography from '@mui/material/Typography';
// import Box from '@mui/material/Box';
// import Input from '@mui/material/Input';
// import axios from "axios";
// import { useLoaderData, useSearchParams } from "react-router-dom";
// import CSRFToken from '../../loaders/CSRFToken';

export default function PasswordResetConfirm() {
    // const loaderData = useLoaderData();
    // const [searchParams, setSearchParams] = useSearchParams();
    // const [alert, setAlert] = useState(undefined);

    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     const data = new FormData(event.currentTarget);
    //     // axios.post(
    //     //     "/dj-rest-auth/password/reset/confirm/",
    //     //     data,
    //     //     {
    //     //         headers: {
    //     //             'Accept': 'application/json',
    //     //             'Content-Type': 'application/json',
    //     //             'X-CSRFToken': CSRFToken
    //     //         }
    //     //     }
    //     // )
    //     // .then(response => {
    //     //     console.log(response);
    //     //     response.data.type = "success";
    //     //     setAlert(response.data);
    //     // })
    //     // .catch(error => {
    //     //     console.log(error);
    //     //     error.response.data.type = "error";
    //     //     setAlert(error.response.data);
    //     // })
    //     // .finally(() => setTimeout(() => {setAlert(undefined);}, 10000))
    // };

    // return (
    //     <>
    //         {alert !== undefined && alert.type !== "error" && Object.keys(alert).map(key => key !== "type" && <Alert severity={alert.type}>{alert[key]}</Alert>)}
    //         {(searchParams.get('uid') !== null && searchParams.get('token') !== null) &&
    //             <>
    //                 <Typography component="h1" variant="h5">
    //                     Reset Password
    //                 </Typography>
    //                 <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
    //                     <Input
    //                         sx={{ "display": "none" }}
    //                         id="uid"
    //                         name="uid"
    //                         readOnly
    //                         required
    //                         value={"pasta"}
    //                     />
    //                     <Input
    //                         sx={{ "display": "none" }}
    //                         id="token"
    //                         name="token"
    //                         readOnly
    //                         required
    //                         value={"sauce"}
    //                     />
    //                     <TextField
    //                         error={alert !== undefined}
    //                         helperText={alert !== undefined && alert.password1 !== undefined && alert.password1}
    //                         margin="normal"
    //                         required
    //                         fullWidth
    //                         name="password1"
    //                         label="Password"
    //                         type="password"
    //                         id="password1"
    //                     />
    //                     <TextField
    //                         error={alert !== undefined}
    //                         helperText={alert !== undefined && alert.password2 !== undefined && alert.password2}
    //                         margin="normal"
    //                         required
    //                         fullWidth
    //                         name="password2"
    //                         label="Confirm Password"
    //                         type="password"
    //                         id="password2"
    //                     />
    //                     <Button
    //                         type="submit"
    //                         fullWidth
    //                         variant="contained"
    //                         sx={{ mt: 3, mb: 2 }}
    //                     >
    //                         Reset Password
    //                     </Button>
    //                     <Grid container>
    //                         <Grid item>
    //                             <Link href="registration" variant="body2">
    //                                 Don't have an account? Sign Up
    //                             </Link>
    //                         </Grid>
    //                     </Grid>
    //                 </Box>
    //             </>
    //         }
    //         {(searchParams.get('uid') === null || searchParams.get("token") === null) &&
    //             <>
    //                 <Typography component="h1" variant="h5">
    //                     Broken Link
    //                 </Typography>
    //                 <Grid container
    //                     direction="row"
    //                     justifyContent="center"
    //                     alignItems="center"
    //                 >
    //                     <Grid item>
    //                         <Link href="password_reset" variant="body2">
    //                             Try resending email to reset password.
    //                         </Link>
    //                     </Grid>
    //                 </Grid>
    //             </>

    //         }
    //     </>
    // );
    return ("hello world")
}