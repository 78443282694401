import React from "react";
import axios from "axios";

export default function VideoSelectionLoader() {
  let response = [];
  // console.log("VIDEO SELECTION LOADER MF")
  const res = axios.all([
    axios.get("/api/v2/pages/?type=video.Video&fields=video,description,preview_length,requires_subscription")
      .catch(error => console.log(error))
      .then(res => { 
        response.push(res)
       }),
    axios.get("/stripe/subscriptions/")
    .catch(error => console.log(error))
    .then(res => { response.push(res) })
  ]).then(() => { return response })
  return res;
}