import React from "react";
import {Box,Grid,Typography} from "@mui/material";

export default function VideoDesc(props) {
    // console.log("Props",props);
    const colors = [ '#267976', '#874973', '#0e5352' ];
    return (<>

            <Grid container>
               
            </Grid>

        {/* Tag and Desc */}
        <Grid container
            direction="row"
            spacing={3}
            id="TagandDescContainer"
        >
                {/* Category / Adjective Tags */}
                <Grid item xs={12}>
                    <Grid 
                        container
                        id="TagGridContainer"
                        direction="row" 
                        rowGap={{xs: 2, md: 0}}
                        justifyContent={{xs: "flex-start", md: "flex-end"}}
                        alignItems={"center"}
                        sx={{minHeight: "50px"}}
                    >
                    {props.tags !== undefined && props.tags.map((tag, i) =>
                    <>
                            <Grid 
                            item 
                            id="TagGridItem"
                            xs={12} 
                            md={3} 
                            p={1}
                            ml={{xs: 0, md: 2}}
                            sx={{backgroundColor: colors[i] }}>
                             {/* Need this box to vertically center Typography */}
                            <Box 
                            pl={{xs: 0, md: 1}}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                                width: "100%",
                            }}>
                            
                            <Typography 
                            variant="tag" 
                            textAlign={"center"}
                            >
                            
                            {tag}
                            </Typography>
                            </Box>
                            </Grid>
                    </>
                    )}
                    </Grid>
                </Grid>

 
        </Grid>
    </>
    )
}