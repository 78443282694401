import React from "react";
import axios from "axios";

export default function SubscriptionsLoader() {
        let response = [];
        const res = axios.all([
                axios.get("/stripe/products/?type=subscription")
                .catch(error=>console.log(error))
                .then(res=> {response.push(res)}),
                axios.get("/stripe/prices/")
                .catch(error=>console.log(error))
                .then(res=> {response.push(res)}),
                axios.get("/stripe/subscriptions/")
                .catch(error=>console.log(error))
                .then(res=> {response.push(res)})
        ]).then(() => {return response})
        return res;
}