import { Typography, Box, Grid, Tooltip, Icon } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { Instagram, Facebook, Email, Send, Phone } from "@mui/icons-material";

export default function Footer() {
  const [footerDetails, setFooterDetails] = useState(undefined);
  // const [contactDetails, setContactDetails] = useState(undefined);

  const getFooterDetails = () => {
    axios
      .get("/api/v2/pages/?type=footer.Footer")
      .catch((error) => console.log("footer error", error))
      .then((response) => {
        // console.log("footer response", response);
        axios
          .get(response.data.items[0].meta.detail_url)
          .catch((error) => console.log("footer details error", error))
          .then((res) => {
            // console.log("footer details response", res);
            setFooterDetails(res.data);
          });
      });
  };

  useEffect(() => {
    getFooterDetails();
  }, []);

  return (
    <>
      {footerDetails !== undefined && (
        <>
          {/* This box adds the background image and size and structure */}
          <Box
            sx={{
              zIndex: 98,
              width: "100%",
              minHeight: "490px",
              height: "490px",
              maxHeight: "490px",
              marginBottom: "-1px",
              backgroundSize: { xs: "120%", sm: "101%" },
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top left",
              // backgroundImage: 'url(../../../static/images/Footer_BG.png)',
              backgroundImage: `url(${footerDetails.background_image.meta.download_url}), linear-gradient(to right, #000000, #000000)`,
              overflowY: "hidden",
            }}
            pt={4}
            m={0}
            mt={5}
          >
            {/* The box below is for centering the content and aligning it with the rest of the website */}
            <Box
              sx={{ width: { xs: "85%", md: "80%", xl: "65%" }, margin: "10% auto 0 auto", color: "white" }}
            >
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                spacing={{ xs: 3, sm: 0 }}
              >
                <Grid item xs={12} sm={5}>
                  <Box sx={{ maxWidth: "100%" }}>
                    <Box
                      component="img"
                      src={`${footerDetails.logo.meta.download_url}`}
                      sx={{ height: "auto", width: "80%" }}
                    >
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Box sx={{ maxWidth: "100%" }}>
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Grid item xs={12} md={10} lg={8}>
                        <Tooltip
                          title="copy to clipboard"
                          placement="right"
                        >
                          <Button
                            variant="textLinks"
                            sx={{fontSize: {xs: "14px", md: "18px", lg: "1.1rem"}, paddingLeft: "0", textAlign: "left", overflowWrap: "break-all", wordBreak: "break-all"}}
                            // href={`mailto:${
                            //   footerDetails.contact_email_address !== "" &&
                            //   footerDetails.contact_email_address
                            // }`}
                            onClick={() => {
                              navigator.clipboard.writeText(
                                footerDetails.contact_email_address,
                              );
                            }}
                          >
                            <Icon
                              sx={{
                                display: "inline-block",
                                marginRight: "6px",
                                marginBottom: "2px",
                              }}
                            >
                              <Email />
                            </Icon>
                            {footerDetails.contact_email_address !== "" &&
                              footerDetails.contact_email_address}
                          </Button>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12} md={10} lg={8}>
                        <Tooltip
                          title="copy to clipboard"
                          placement="right"
                        >
                          <Button
                            variant="textLinks"
                            sx={{ paddingLeft: "0" }}
                            // href={`tel:${
                            //   footerDetails.contact_email_address !== "" &&
                            //   footerDetails.contact_phone_number
                            // }`}
                            onClick={() => {
                              navigator.clipboard.writeText(
                                footerDetails.contact_phone_number,
                              );
                            }}
                          >
                            <Icon
                              sx={{
                                display: "inline-block",
                                marginRight: "6px",
                                marginBottom: "2px",
                              }}
                            >
                              <Phone />
                            </Icon>
                            {footerDetails.contact_phone_number !== "" &&
                              footerDetails.contact_phone_number}
                          </Button>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12} md={10} lg={8}>
                        <Typography variant="caption" color={"white"} sx={{ maxWidth: "100%" }}>
                        &copy; Copyright{" "}
                          {(new Date().getFullYear())}{" "}
                          {footerDetails.title}. All Rights Reserved.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
