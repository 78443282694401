import React from "react";
import CSRFToken from "../../loaders/CSRFToken";
import Wrapper from "../authentication/Wrapper";
import Login from "../authentication/Login";
import Register from "../authentication/Register";
import PasswordChange from "../authentication/PasswordChange";
import PasswordReset from "../authentication/PasswordReset";
import PasswordResetConfirm from "../authentication/PasswordResetConfirm";
import MyAccount from "../authentication/MyAccount";
import UserLoader from "../../loaders/UserLoader";
// import UpdateUser from "../authentication/UpdateUser";
import VerifyEmail from "../authentication/VerifyEmail";
import ConfirmEmail from "../authentication/ConfirmEmail";
const AuthPages = [{
    element: <Wrapper />,
    path: "authentication",
    children: [
      {
        path: "",
        element: <Login />,
        loader: UserLoader
      },
      {
        path: "my_account",
        element: <MyAccount />,
        loader: UserLoader
      },
      {
        path: "login",
        element: <Login />,
        loader: UserLoader
      },
      {
        path: "registration",
        element: <Register />,
        loader: UserLoader
      },
      {
        path: "password_reset",
        element: <PasswordReset />,
        loader: UserLoader
      },
      {
        path: "password_confirm",
        element: <PasswordResetConfirm />,
        loader: UserLoader
      },
      {
        path: "password_change",
        element: <PasswordChange />,
        loader: UserLoader
      },
      // {
      //   path: "update_user",
      //   element: <UpateUser />
      // },
      {
        path: "verify_email",
        element: <VerifyEmail />
      },
    ],
  },
  {
    path: "account_confirm_email",
    element: <Wrapper />,
    children: [
      {
        path: ":key",
        element: <ConfirmEmail />
      }
    ],
  },
  ];
export default AuthPages;