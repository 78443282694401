import React from "react";
import { Box} from "@mui/material";


export default function Quotemark() {
    return (
        <>
            <Box
                component="img"
                src="/static/images/Quotemark.png"
                sx={{
                width: {xs: '22%', lg: '34%'},
                height: 'auto',
                maxHeight: '65%',
                padding: '0',
            }}
            
            ></Box>
        </>
    )
}